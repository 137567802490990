import { FunctionComponent } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  SectionHeading,
  LabelContainer,
  RadioButtonsContainer,
  ItemTextInput,
  CheckboxContainer,
  ItemsGap5,
} from "../../commonStyles";
import DateField from "../../../../../core/components/DateField";
import Radio from "../../../../../core/components/Radio";
import styled from "styled-components";
import Checkbox from "../../../../../core/components/Checkbox";
import Text from "../../../../../core/components/Text";
import { EcContractRenewal } from "../../../../../core/enums/employmentConditions";
import { FormBlockSectionProps } from "../../types";
import { LocalNumberFieldSmall } from "../../../../../job/pages/JobFormPage/commonStyle";

interface ContractPeriodSectionProps extends FormBlockSectionProps {}


const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RenewalLimitDetail = styled(ItemsGap5)`
  align-items: center;
`;

const ContractPeriodSection: FunctionComponent<ContractPeriodSectionProps> = ({
  employmentConditions: ec,
  onChange
}) => {
  return (
    <FormSection>
      <SectionHeading>雇用契約期間</SectionHeading>
      <FormRow>
        <LabelContainer>
          <ItemLabel>始期</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <DateField
            showEmptyOption
            value={ec?.contractPeriodStartDate || ""}
            onDateChange={val => onChange?.({ contractPeriodStartDate: val || null })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>終期</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <DateField
            showEmptyOption
            value={ec?.contractPeriodEndDate || ""}
            onDateChange={val => onChange?.({ contractPeriodEndDate: val || null })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>入国予定日</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <DateField
            showEmptyOption
            value={ec?.contractPeriodScheduledEntryDate || ""}
            onDateChange={val => onChange?.({ contractPeriodScheduledEntryDate: val || null })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>契約更新</ItemLabel>
        </LabelContainer>
        <InputContainer>
         <RadioButtonsContainer>
            <Radio
                checked={ec?.contractPeriodRenewal === EcContractRenewal.AutomaticRenewal}
                value={EcContractRenewal.AutomaticRenewal}
                onValueChange={val => onChange?.({ contractPeriodRenewal: val })}
            >
              自動的に更新
            </Radio>
            <Radio
                checked={ec?.contractPeriodRenewal === EcContractRenewal.ConditionalRenewal}
                value={EcContractRenewal.ConditionalRenewal}
                onValueChange={val => onChange?.({ contractPeriodRenewal: val })}
                style={{ marginLeft: 0 }}
            >
              更新する場合がある
            </Radio>
            <Radio
                checked={ec?.contractPeriodRenewal === EcContractRenewal.NoRenewal}
                value={EcContractRenewal.NoRenewal}
                onValueChange={val => onChange?.({ contractPeriodRenewal: val })}
            >
              更新しない
            </Radio>
        </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
      { ec?.contractPeriodRenewal === EcContractRenewal.ConditionalRenewal &&
        <>
          <FormRow>
            <LabelContainer>
              <ItemLabel>契約更新の判断基準</ItemLabel>
            </LabelContainer>
            <InputContainer>
              <CheckboxesContainer>
                <CheckboxContainer>
                  <Checkbox 
                    checked={!!ec?.contractPeriodRenewalConditionWorkload}
                    onValueChange={val => onChange?.({ contractPeriodRenewalConditionWorkload: val })}
                  />
                  <Text>契約期間満了時の業務量</Text>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox 
                    checked={!!ec?.contractPeriodRenewalConditionEmployeePerformance}
                    onValueChange={val => onChange?.({ contractPeriodRenewalConditionEmployeePerformance: val })}
                  />
                  <Text>労働者の勤務成績、態度</Text>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox 
                    checked={!!ec?.contractPeriodRenewalConditionEmployeeAbility}
                    onValueChange={val => onChange?.({ contractPeriodRenewalConditionEmployeeAbility: val })}
                  />
                  <Text>労働者の業務を遂行する能力</Text>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox 
                    checked={!!ec?.contractPeriodRenewalConditionCompanySituation}
                    onValueChange={val => onChange?.({ contractPeriodRenewalConditionCompanySituation: val })}
                  />
                  <Text>会社の経営状況</Text>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox 
                    checked={!!ec?.contractPeriodRenewalConditionProgressSituation}
                    onValueChange={val => onChange?.({ contractPeriodRenewalConditionProgressSituation: val })}
                  />
                  <Text>従事している業務の進捗状況</Text>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox 
                    checked={!!ec?.contractPeriodRenewalConditionOther}
                    onValueChange={val => onChange?.({ contractPeriodRenewalConditionOther: val })}
                  />
                  <Text>その他</Text>
                </CheckboxContainer>
              </CheckboxesContainer>
            </InputContainer>
          </FormRow>

          { ec?.contractPeriodRenewalConditionOther && 
            <FormRow>
              <LabelContainer>
                <ItemLabel>契約更新の判断基準（その他）</ItemLabel>
              </LabelContainer>
              <InputContainer>
                <ItemTextInput 
                  placeholder="入力してください"
                  value={ec?.contractPeriodRenewalConditionOtherDetail || ""}
                  onTextChange={val => onChange?.({ contractPeriodRenewalConditionOtherDetail: val })}
                />
              </InputContainer>
            </FormRow>
          }
        </>
      }
      <FormRow>
        <LabelContainer>
          <ItemLabel>更新上限の有無</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
                checked={ec?.contractPeriodRenewalUpdateLimit === true}
                onValueChange={() => onChange?.({ contractPeriodRenewalUpdateLimit: true })}
            >
              <RenewalLimitDetail>
                <span>あり（更新</span>
                <LocalNumberFieldSmall
                  disabled={ec?.contractPeriodRenewalUpdateLimit !== true} 
                  placeholder="3"
                  value={
                    ec?.contractPeriodRenewalUpdateLimit === true
                      ? (ec?.contractPeriodRenewalNumberOfUpdates || "")
                      : ""
                  }
                  onValueChange={val => onChange?.({ contractPeriodRenewalNumberOfUpdates: val })}
                />
                <span>回まで</span>
                <span>/通算契約期間</span>
                <LocalNumberFieldSmall
                  disabled={ec?.contractPeriodRenewalUpdateLimit !== true}
                  placeholder="3"
                  value={
                    ec?.contractPeriodRenewalUpdateLimit === true
                      ? (ec?.contractPeriodRenewalTotalContractPeriod || "")
                      : ""
                  }
                  onValueChange={val => onChange?.({ contractPeriodRenewalTotalContractPeriod: val })}
                />
                <span>年まで</span>
              </RenewalLimitDetail>
            </Radio>
            <Radio
                checked={ec?.contractPeriodRenewalUpdateLimit === false}
                onValueChange={() => onChange?.({ contractPeriodRenewalUpdateLimit: false })}
            >
              なし
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>

      <FormRow>
        <LabelContainer>
          <ItemLabel>無期雇用契約時の労働条件変更の有無</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
                checked={ec?.contractPeriodRenewalConditionsChange === true}
                onValueChange={() => onChange?.({ contractPeriodRenewalConditionsChange: true })}
            >
              あり
            </Radio>
            <Radio
                checked={ec?.contractPeriodRenewalConditionsChange === false}
                onValueChange={() => onChange?.({ contractPeriodRenewalConditionsChange: false })}
                style={{ marginLeft: 0 }}
            >
              なし
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default ContractPeriodSection;
