import { FunctionComponent, useEffect } from "react";
import { GridRowsContainer } from "../commonStyle";
import { findItemByKind, isEmpty, isNotEmpty } from "../../../../core/utils";
import { EditableTranslationKind } from "../../../enums/translation";
import { JobFormSectionProps, TranslationItem } from "../type";
import { JobEditableTranslation } from "../../../types/api";
import { useReducerContext } from "../../../../core/contexts/ReducerContext";
import MultilingualInputField from "../components/MultilingualInputField";

interface MultilingualDisplaySectionProps extends JobFormSectionProps {
  reflectOrgInfo?: boolean;
}

const MultilingualDisplaySection: FunctionComponent<
  MultilingualDisplaySectionProps
> = ({ 
  job, 
  onChange, 
  onClickLangInputButton,
  reflectOrgInfo,
}) => {
  const { state } = useReducerContext();
  const jobEditableTrans = job?.editableTranslations ?? [];
  const empEditableTrans = state?.company?.employer?.editableTranslations ?? [];
  const labelDic: Partial<Record<EditableTranslationKind, string>> = {
    [EditableTranslationKind.JobClassification]: "業務区分",
    [EditableTranslationKind.JobClassificationAfterEmployment]: "業務区分",
    [EditableTranslationKind.WorkHoursNote]: "労働時間の備考",
    [EditableTranslationKind.HolidaysOtherFixedHolidays]: "その他の定例休日",
    [EditableTranslationKind.HolidaysOtherIrregularHolidays]: "その他の非定例休日",
    [EditableTranslationKind.HolidaysNote]: "休日の備考",
    [EditableTranslationKind.LeavesOtherPaidLeaves]: "その他の休暇（有給）",
    [EditableTranslationKind.LeavesOtherUnpaidLeaves]: "その他の休暇（無休）",
    [EditableTranslationKind.LeavesNote]: "休暇の備考",
    [EditableTranslationKind.CompensationBenefits]: "諸手当（時間外労働の割増賃金を除く）",
    [EditableTranslationKind.ResignationNote]: "退職の備考",
    [EditableTranslationKind.OtherSocialInsuranceCoverageOtherDetail]: "社会保険等の加入状況（その他）",
    [EditableTranslationKind.OtherWorkRegulationsCheckMethod]: "就業規則確認方法",
    [EditableTranslationKind.BenefitsBenefit1Name]: "手当1",
    [EditableTranslationKind.BenefitsBenefit1CalculationMethod]: "手当1の計算方法",
    [EditableTranslationKind.BenefitsBenefit2Name]: "手当2",
    [EditableTranslationKind.BenefitsBenefit2CalculationMethod]: "手当2の計算方法",
    [EditableTranslationKind.BenefitsBenefit3Name]: "手当3",
    [EditableTranslationKind.BenefitsBenefit3CalculationMethod]: "手当3の計算方法",
    [EditableTranslationKind.BenefitsBenefit4Name]: "手当4",
    [EditableTranslationKind.BenefitsBenefit4CalculationMethod]: "手当4の計算方法",
    [EditableTranslationKind.DeductionsOtherDeductions1Name]: "その他控除1",
    [EditableTranslationKind.DeductionsOtherDeductions2Name]: "その他控除2",
    [EditableTranslationKind.DeductionsOtherDeductions3Name]: "その他控除3",
    [EditableTranslationKind.DeductionsOtherDeductions4Name]: "その他控除4",
  }

  const findTranslation = (kind: EditableTranslationKind) =>
    findItemByKind(jobEditableTrans, kind);

  const translationItems: TranslationItem[] = [
    {
      label: labelDic[EditableTranslationKind.JobClassification],
      translation: findTranslation(
        EditableTranslationKind.JobClassification
      ),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.JobClassification],
        kind: EditableTranslationKind.JobClassification,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.JobClassificationAfterEmployment],
      note: "※雇入後に変更がある場合",
      translation: findTranslation(
        EditableTranslationKind.JobClassificationAfterEmployment
      ),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.JobClassificationAfterEmployment],
        kind: EditableTranslationKind.JobClassificationAfterEmployment,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.WorkHoursNote],
      translation: findTranslation(EditableTranslationKind.WorkHoursNote),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.WorkHoursNote],
        kind: EditableTranslationKind.WorkHoursNote,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.HolidaysOtherFixedHolidays],
      translation: findTranslation(EditableTranslationKind.HolidaysOtherFixedHolidays),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.HolidaysOtherFixedHolidays],
        kind: EditableTranslationKind.HolidaysOtherFixedHolidays,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.HolidaysOtherIrregularHolidays],
      translation: findTranslation(EditableTranslationKind.HolidaysOtherIrregularHolidays),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.HolidaysOtherIrregularHolidays],
        kind: EditableTranslationKind.HolidaysOtherIrregularHolidays,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.HolidaysNote],
      translation: findTranslation(EditableTranslationKind.HolidaysNote),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.HolidaysNote],
        kind: EditableTranslationKind.HolidaysNote,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.LeavesOtherPaidLeaves],
      translation: findTranslation(EditableTranslationKind.LeavesOtherPaidLeaves),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.LeavesOtherPaidLeaves],
        kind: EditableTranslationKind.LeavesOtherPaidLeaves,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.LeavesOtherUnpaidLeaves],
      translation: findTranslation(EditableTranslationKind.LeavesOtherUnpaidLeaves),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.LeavesOtherUnpaidLeaves],
        kind: EditableTranslationKind.LeavesOtherUnpaidLeaves,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.LeavesNote],
      translation: findTranslation(EditableTranslationKind.LeavesNote),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.LeavesNote],
        kind: EditableTranslationKind.LeavesNote,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.CompensationBenefits],
      translation: findTranslation(EditableTranslationKind.CompensationBenefits),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.CompensationBenefits],
        kind: EditableTranslationKind.CompensationBenefits,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.ResignationNote],
      translation: findTranslation(EditableTranslationKind.ResignationNote),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.ResignationNote],
        kind: EditableTranslationKind.ResignationNote,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.OtherSocialInsuranceCoverageOtherDetail],
      translation: findTranslation(EditableTranslationKind.OtherSocialInsuranceCoverageOtherDetail),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.OtherSocialInsuranceCoverageOtherDetail],
        kind: EditableTranslationKind.OtherSocialInsuranceCoverageOtherDetail,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.OtherWorkRegulationsCheckMethod],
      translation: findTranslation(EditableTranslationKind.OtherWorkRegulationsCheckMethod),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.OtherWorkRegulationsCheckMethod],
        kind: EditableTranslationKind.OtherWorkRegulationsCheckMethod,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsBenefit1Name],
      translation: findTranslation(EditableTranslationKind.BenefitsBenefit1Name),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.BenefitsBenefit1Name],
        kind: EditableTranslationKind.BenefitsBenefit1Name,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsBenefit1CalculationMethod],
      translation: findTranslation(EditableTranslationKind.BenefitsBenefit1CalculationMethod),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.BenefitsBenefit1CalculationMethod],
        kind: EditableTranslationKind.BenefitsBenefit1CalculationMethod,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsBenefit2Name],
      translation: findTranslation(EditableTranslationKind.BenefitsBenefit2Name),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.BenefitsBenefit2Name],
        kind: EditableTranslationKind.BenefitsBenefit2Name,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsBenefit2CalculationMethod],
      translation: findTranslation(EditableTranslationKind.BenefitsBenefit2CalculationMethod),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.BenefitsBenefit2CalculationMethod],
        kind: EditableTranslationKind.BenefitsBenefit2CalculationMethod,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsBenefit3Name],
      translation: findTranslation(EditableTranslationKind.BenefitsBenefit3Name),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.BenefitsBenefit3Name],
        kind: EditableTranslationKind.BenefitsBenefit3Name,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsBenefit3CalculationMethod],
      translation: findTranslation(EditableTranslationKind.BenefitsBenefit3CalculationMethod),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.BenefitsBenefit3CalculationMethod],
        kind: EditableTranslationKind.BenefitsBenefit3CalculationMethod,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsBenefit4Name],
      translation: findTranslation(EditableTranslationKind.BenefitsBenefit4Name),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.BenefitsBenefit4Name],
        kind: EditableTranslationKind.BenefitsBenefit4Name,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsBenefit4CalculationMethod],
      translation: findTranslation(EditableTranslationKind.BenefitsBenefit4CalculationMethod),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.BenefitsBenefit4CalculationMethod],
        kind: EditableTranslationKind.BenefitsBenefit4CalculationMethod,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.DeductionsOtherDeductions1Name],
      translation: findTranslation(EditableTranslationKind.DeductionsOtherDeductions1Name),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.DeductionsOtherDeductions1Name],
        kind: EditableTranslationKind.DeductionsOtherDeductions1Name,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.DeductionsOtherDeductions2Name],
      translation: findTranslation(EditableTranslationKind.DeductionsOtherDeductions2Name),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.DeductionsOtherDeductions2Name],
        kind: EditableTranslationKind.DeductionsOtherDeductions2Name,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.DeductionsOtherDeductions3Name],
      translation: findTranslation(EditableTranslationKind.DeductionsOtherDeductions3Name),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.DeductionsOtherDeductions3Name],
        kind: EditableTranslationKind.DeductionsOtherDeductions3Name,
      }),
    },
    {
      label: labelDic[EditableTranslationKind.DeductionsOtherDeductions4Name],
      translation: findTranslation(EditableTranslationKind.DeductionsOtherDeductions4Name),
      onClickInputButton: () => onClickLangInputButton?.({
        heading: labelDic[EditableTranslationKind.DeductionsOtherDeductions4Name],
        kind: EditableTranslationKind.DeductionsOtherDeductions4Name,
      }),
    },
  ];

  useEffect(() => {
    if (!reflectOrgInfo) 
      return;

    const newJobTrans: JobEditableTranslation[] = jobEditableTrans.map(et => {
      const kind = et.kind;
      const empTrans = findItemByKind(empEditableTrans, kind);
      const jobTrans = findItemByKind(jobEditableTrans, kind);

      if (isEmpty(jobTrans?.jaManual) && isNotEmpty(empTrans?.jaManual)) {
        return {
          jaManual: empTrans?.jaManual || null,
          enManual: empTrans?.enManual || null,
          idManual: empTrans?.idManual || null,
          neManual: empTrans?.neManual || null,
          viManual: empTrans?.viManual || null,
          myManual: empTrans?.myManual || null,
          enAuto: empTrans?.enAuto || null,
          idAuto: empTrans?.idAuto || null,
          neAuto: empTrans?.neAuto || null,
          viAuto: empTrans?.viAuto || null,
          myAuto: empTrans?.myAuto || null,
          kind,
        }
      } else {
        return et;
      }
    });

    onChange?.({ editableTranslations: newJobTrans });
  }, [reflectOrgInfo]);

  return (
    <>
      <GridRowsContainer>
        {translationItems.map((item, index) => (
          <MultilingualInputField
            label={item.label}
            note={item.note}
            translation={item.translation}
            onClickInputButton={item.onClickInputButton}
            key={`multilingual-input-field-${index}`}
          />
        ))}
      </GridRowsContainer>

      
    </>
  );
};

export default MultilingualDisplaySection;
