import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import {  Column5, InputValue, Row, Item10, Item20, Item5, Segment, FlexRowColumn } from "../../commonStyles";
import { BlockNoteContainer,  Indent, ListBlock, ListBlockContent, SectionHeadingContainer, ListBlockIndex, ListBlocksContainer, Section } from "./styles";
import CheckMark from "../../components/CheckMark";
import { TemplateSectionProps } from "../../types";
import { formatDecimalString } from "../../util";
import { Trans, useTranslation } from "react-i18next";
import { LanguageCode } from "../../../../../core/enums/language";
import { EcVariableWorkingHoursUnit } from "../../../../../core/enums/employmentConditions";
import { isEmpty } from "../../../../../core/utils";
import Column from "../../../../../core/components/Column";
import { EditableTranslationKind } from "../../../../../job/enums/translation";

interface WorkHoursSectionProps extends TemplateSectionProps {}

const WorkHoursSection: FunctionComponent<WorkHoursSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  i18nFormPrefixKey
}) => {
  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tOptions = { lng: transLang }
  const tFixedToJa = i18n.getFixedT(LanguageCode.Japanese);
  const tFixedToTransLang = i18n.getFixedT(transLang);
  const tFixedToTransLangWithPrefix = i18n.getFixedT(transLang, "", i18nFormPrefixKey);
  const tFixed = (key: string): string => tFixedToTransLangWithPrefix(key).toString();
  const getTKey = (key: string) => `${i18nFormPrefixKey}.${key}`;

  const [workStartTimeHours, setWorkStartTimeHours] = useState<number | null>();
  const [workStartTimeMinutes, setWorkStartTimeMinutes] = useState<number | null>();
  const [workEndTimeHours, setWorkEndTimeHours] = useState<number | null>();
  const [workEndTimeMinutes, setWorkEndTimeMinutes] = useState<number | null>();
  const [workHourPerDayHours, setWorkHourPerDayHours] = useState<number | null>();
  const [workHourPerDayMinutes, setWorkHourPerDayMinutes] = useState<number | null>();
  const [variableWorkingHours, setVariableWorkingHours] = useState<boolean>();
  const [variableWorkingHoursUnitInJa, setVariableWorkingHoursUnitInJa] = useState<string>("");
  const [variableWorkingHoursUnitInTrans, setVariableWorkingHoursUnitInTrans] = useState<string>("");
  const [shiftSystem, setShiftSystem] = useState<boolean>();

  const [shift1StartTimeHours, setShift1StartTimeHours] = useState<number | null>();
  const [shift1StartTimeMinutes, setShift1StartTimeMinutes] = useState<number | null>();
  const [shift1EndTimeHours, setShift1EndTimeHours] = useState<number | null>();
  const [shift1EndTimeMinutes, setShift1EndTimeMinutes] = useState<number | null>();
  const [shift1ApplicableDate, setShift1ApplicableDate] = useState<string>("");
  const [shift1ApplicableDateTrans, setShift1ApplicableDateTrans] = useState<string>("");
  const [shift1StandardWorkHoursHours, setShift1StandardWorkHoursHours] = useState<number | null>();
  const [shift1StandardWorkHoursMinutes, setShift1StandardWorkHoursMinutes] = useState<number | null>();

  const [shift2StartTimeHours, setShift2StartTimeHours] = useState<number | null>();
  const [shift2StartTimeMinutes, setShift2StartTimeMinutes] = useState<number | null>();
  const [shift2EndTimeHours, setShift2EndTimeHours] = useState<number | null>();
  const [shift2EndTimeMinutes, setShift2EndTimeMinutes] = useState<number | null>();
  const [shift2ApplicableDate, setShift2ApplicableDate] = useState<string>("");
  const [shift2ApplicableDateTrans, setShift2ApplicableDateTrans] = useState<string>("");
  const [shift2StandardWorkHoursHours, setShift2StandardWorkHoursHours] = useState<number | null>();
  const [shift2StandardWorkHoursMinutes, setShift2StandardWorkHoursMinutes] = useState<number | null>();

  const [shift3StartTimeHours, setShift3StartTimeHours] = useState<number | null>();
  const [shift3StartTimeMinutes, setShift3StartTimeMinutes] = useState<number | null>();
  const [shift3EndTimeHours, setShift3EndTimeHours] = useState<number | null>();
  const [shift3EndTimeMinutes, setShift3EndTimeMinutes] = useState<number | null>();
  const [shift3ApplicableDate, setShift3ApplicableDate] = useState<string>("");
  const [shift3ApplicableDateTrans, setShift3ApplicableDateTrans] = useState<string>("");
  const [shift3StandardWorkHoursHours, setShift3StandardWorkHoursHours] = useState<number | null>();
  const [shift3StandardWorkHoursMinutes, setShift3StandardWorkHoursMinutes] = useState<number | null>();

  const [breakTime, setBreakTime] = useState<number | null>();
  const [normalWorkHoursWeeklyHours, setNormalWorkHoursWeeklyHours] = useState<number | null>();
  const [normalWorkHoursWeeklyMinutes, setNormalWorkHoursWeeklyMinutes] = useState<number | null>();
  const [normalWorkHoursMonthlyHours, setNormalWorkHoursMonthlyHours] = useState<number | null>();
  const [normalWorkHoursMonthlyMinutes, setNormalWorkHoursMonthlyMinutes] = useState<number | null>();
  const [normalWorkHoursAnnualHours, setNormalWorkHoursAnnualHours] = useState<number | null>();
  const [normalWorkHoursAnnualMinutes, setNormalWorkHoursAnnualMinutes] = useState<number | null>();
  const [normalWorkDaysWeeklyJa, setNormalWorkDaysWeeklyJa] = useState<string>();
  const [normalWorkDaysWeeklyEn, setNormalWorkDaysWeeklyEn] = useState<string>();
  const [normalWorkDaysMonthly, setNormalWorkDaysMonthly] = useState<string>();
  const [normalWorkDaysAnnually, setNormalWorkDaysAnnually] = useState<string>();
  const [notesOnWork, setNotesOnWork] = useState<string>("");
  const [notesOnWorkTrans, setNotesOnWorkTrans] = useState<string>("");

  const getTimeValueComponent = (hourOrMinute?: number | null) => 
    ({ children }: { children?: ReactNode }) => 
      (hourOrMinute == null || isEmpty(children))
        ? <></>
        : <Item5><InputValue>{children}</InputValue></Item5>;

  const getTimeUnitComponent = (hourOrMinute?: number | null) => 
    ({ children }: { children?: ReactNode }) => 
      (hourOrMinute == null || isEmpty(children))
        ? <></>
        : <Item5>{children}</Item5>;

  const getDisplayMinutes = (minutes: number | null | undefined) => {
    return minutes != null && minutes < 10 ? String(minutes).padStart(2, "0") : minutes;
  }

  const WorkingStartHour = getTimeValueComponent(workStartTimeHours);
  const WorkingStartHourUnit = getTimeUnitComponent(workStartTimeHours);
  const WorkingStartMinute = getTimeValueComponent(workStartTimeMinutes);
  const WorkingStartMinuteUnit = getTimeUnitComponent(workStartTimeMinutes);
  const WorkingEndHour = getTimeValueComponent(workEndTimeHours);
  const WorkingEndHourUnit = getTimeUnitComponent(workEndTimeHours);
  const WorkingEndMinute = getTimeValueComponent(workEndTimeMinutes);
  const WorkingEndMinuteUnit = getTimeUnitComponent(workEndTimeMinutes);

  const WorkingHourPerDayHours = getTimeValueComponent(workHourPerDayHours);
  const WorkingHourPerDayHoursUnit = getTimeUnitComponent(workHourPerDayHours);
  const WorkingHourPerDayMinutes = getTimeValueComponent(workHourPerDayMinutes)
  const WorkingHourPerDayMinutesUnit = getTimeUnitComponent(workHourPerDayMinutes);

  const VariableWorkingHoursUnit = ({ children }: { children?: ReactNode }) =>
    variableWorkingHoursUnitInTrans
      ? <Item5><InputValue>{children}</InputValue></Item5>
      : <></>;

  const Shift1StartHour = getTimeValueComponent(shift1StartTimeHours);
  const Shift1StartHourUnit = getTimeUnitComponent(shift1StartTimeHours);
  const Shift1StartMinute = getTimeValueComponent(shift1StartTimeMinutes);
  const Shift1StartMinuteUnit = getTimeUnitComponent(shift1StartTimeMinutes);
  const Shift1EndHour = getTimeValueComponent(shift1EndTimeHours);
  const Shift1EndHourUnit = getTimeUnitComponent(shift1EndTimeHours);
  const Shift1EndMinute = getTimeValueComponent(shift1EndTimeMinutes);
  const Shift1EndMinuteUnit = getTimeUnitComponent(shift1EndTimeMinutes);
  const Shift1StandardWorkingTimeHour = getTimeValueComponent(shift1StandardWorkHoursHours);
  const Shift1StandardWorkingTimeHourUnit = getTimeUnitComponent(shift1StandardWorkHoursHours);
  const Shift1StandardWorkingTimeMinute = getTimeValueComponent(shift1StandardWorkHoursMinutes);
  const Shift1StandardWorkingTimeMinuteUnit = getTimeUnitComponent(shift1StandardWorkHoursMinutes);

  const Shift2StartHour = getTimeValueComponent(shift2StartTimeHours);
  const Shift2StartHourUnit = getTimeUnitComponent(shift2StartTimeHours);
  const Shift2StartMinute = getTimeValueComponent(shift2StartTimeMinutes);
  const Shift2StartMinuteUnit = getTimeUnitComponent(shift2StartTimeMinutes);
  const Shift2EndHour = getTimeValueComponent(shift2EndTimeHours);
  const Shift2EndHourUnit = getTimeUnitComponent(shift2EndTimeHours);
  const Shift2EndMinute = getTimeValueComponent(shift2EndTimeMinutes);
  const Shift2EndMinuteUnit = getTimeUnitComponent(shift2EndTimeMinutes);
  const Shift2StandardWorkingTimeHour = getTimeValueComponent(shift2StandardWorkHoursHours);
  const Shift2StandardWorkingTimeHourUnit = getTimeUnitComponent(shift2StandardWorkHoursHours);
  const Shift2StandardWorkingTimeMinute = getTimeValueComponent(shift2StandardWorkHoursMinutes);  
  const Shift2StandardWorkingTimeMinuteUnit = getTimeUnitComponent(shift2StandardWorkHoursMinutes);

  const Shift3StartHour = getTimeValueComponent(shift3StartTimeHours);
  const Shift3StartHourUnit = getTimeUnitComponent(shift3StartTimeHours);
  const Shift3StartMinute = getTimeValueComponent(shift3StartTimeMinutes);
  const Shift3StartMinuteUnit = getTimeUnitComponent(shift3StartTimeMinutes);
  const Shift3EndHour = getTimeValueComponent(shift3EndTimeHours);
  const Shift3EndHourUnit = getTimeUnitComponent(shift3EndTimeHours);
  const Shift3EndMinute = getTimeValueComponent(shift3EndTimeMinutes);
  const Shift3EndMinuteUnit = getTimeUnitComponent(shift3EndTimeMinutes);
  const Shift3StandardWorkingTimeHour = getTimeValueComponent(shift3StandardWorkHoursHours);
  const Shift3StandardWorkingTimeHourUnit = getTimeUnitComponent(shift3StandardWorkHoursHours);
  const Shift3StandardWorkingTimeMinute = getTimeValueComponent(shift3StandardWorkHoursMinutes);
  const Shift3StandardWorkingTimeMinuteUnit = getTimeUnitComponent(shift3StandardWorkHoursMinutes);

  const BreakTimeMin = ({ children }: { children?: ReactNode }) =>
    <Item10><InputValue>{children}</InputValue></Item10>;
  
  const NormalWorkingHoursWeeklyHour = getTimeValueComponent(normalWorkHoursWeeklyHours);
  const NormalWorkingHoursWeeklyHourUnit = getTimeUnitComponent(normalWorkHoursWeeklyHours);
  const NormalWorkingHoursWeeklyMinute = getTimeValueComponent(normalWorkHoursWeeklyMinutes);
  const NormalWorkingHoursWeeklyMinuteUnit = getTimeUnitComponent(normalWorkHoursWeeklyMinutes);

  const NormalWorkingHoursMonthlyHour = getTimeValueComponent(normalWorkHoursMonthlyHours);
  const NormalWorkingHoursMonthlyHourUnit = getTimeUnitComponent(normalWorkHoursMonthlyHours);
  const NormalWorkingHoursMonthlyMinute = getTimeValueComponent(normalWorkHoursMonthlyMinutes);
  const NormalWorkingHoursMonthlyMinuteUnit = getTimeUnitComponent(normalWorkHoursMonthlyMinutes);

  const NormalWorkingHoursAnnualHour = getTimeValueComponent(normalWorkHoursAnnualHours);
  const NormalWorkingHoursAnnualHourUnit = getTimeUnitComponent(normalWorkHoursAnnualHours);
  const NormalWorkingHoursAnnualMinute = getTimeValueComponent(normalWorkHoursAnnualMinutes);
  const NormalWorkingHoursAnnualMinuteUnit = getTimeUnitComponent(normalWorkHoursAnnualMinutes);

  const displayWorkStartTimeMinutes = getDisplayMinutes(workStartTimeMinutes);
  const displayWorkEndTimeMinutes = getDisplayMinutes(workEndTimeMinutes);
  const displayWorkHourPerDayMinutes = getDisplayMinutes(workHourPerDayMinutes);
  const displayShift1StartTimeMinutes = getDisplayMinutes(shift1StartTimeMinutes);
  const displayShift1EndTimeMinutes = getDisplayMinutes(shift1EndTimeMinutes);
  const displayShift1StandardWorkHoursMinutes = getDisplayMinutes(shift1StandardWorkHoursMinutes);
  const displayShift2StartTimeMinutes = getDisplayMinutes(shift2StartTimeMinutes);
  const displayShift2EndTimeMinutes = getDisplayMinutes(shift2EndTimeMinutes);
  const displayShift2StandardWorkHoursMinutes = getDisplayMinutes(shift2StandardWorkHoursMinutes);
  const displayShift3StartTimeMinutes = getDisplayMinutes(shift3StartTimeMinutes);
  const displayShift3EndTimeMinutes = getDisplayMinutes(shift3EndTimeMinutes);
  const displayShift3StandardWorkHoursMinutes = getDisplayMinutes(shift3StandardWorkHoursMinutes);
  const displayNormalWorkHoursWeeklyMinutes = getDisplayMinutes(normalWorkHoursWeeklyMinutes);
  const displayNormalWorkHoursMonthlyMinutes = getDisplayMinutes(normalWorkHoursMonthlyMinutes);
  const displayNormalWorkHoursAnnualMinutes = getDisplayMinutes(normalWorkHoursAnnualMinutes);

  useEffect(() => {
    if (!ec) 
      return;

    setWorkStartTimeHours(ec.workHoursStartTimeHour);
    setWorkStartTimeMinutes(ec.workHoursStartTimeMinute);
    setWorkEndTimeHours(ec.workHoursEndTimeHour);
    setWorkEndTimeMinutes(ec.workHoursEndTimeMinute);
    setWorkHourPerDayHours(ec.workHoursNormalWorkingHour);
    setWorkHourPerDayMinutes(ec.workHoursNormalWorkingMinute);
    setVariableWorkingHours(ec.workHoursVariableWorkingHoursSystem);

    switch (ec.workHoursVariableWorkingHoursSystemUnit) {
      case EcVariableWorkingHoursUnit.Year:
        {
          const key = "job.variable_working_hours_unit.1year";
          setVariableWorkingHoursUnitInJa(tFixedToJa(key));
          setVariableWorkingHoursUnitInTrans(tFixedToTransLang(key));
        }
        break;

      case EcVariableWorkingHoursUnit.Month:
        {
          const key = "job.variable_working_hours_unit.1month";
          setVariableWorkingHoursUnitInJa(tFixedToJa(key));
          setVariableWorkingHoursUnitInTrans(tFixedToTransLang(key));
        }
        break;

      case EcVariableWorkingHoursUnit.Week:
        {
          const key = "job.variable_working_hours_unit.1week";
          setVariableWorkingHoursUnitInJa(tFixedToJa(key));
          setVariableWorkingHoursUnitInTrans(tFixedToTransLang(key));
        }

    }

    setShiftSystem(ec.workHoursShiftSystem);

    setShift1StartTimeHours(ec.workHoursShiftSystem_1StartTimeHour);
    setShift1StartTimeMinutes(ec.workHoursShiftSystem_1StartTimeMinute);
    setShift1EndTimeHours(ec.workHoursShiftSystem_1EndTimeHour);
    setShift1EndTimeMinutes(ec.workHoursShiftSystem_1EndTimeMinute);
    setShift1ApplicableDate(ec.workHoursShiftSystem_1ApplicationDate ?? "");
    setShift1ApplicableDateTrans(transHandler.getTranslation("workHoursShiftSystem_1ApplicationDate"));
    setShift1StandardWorkHoursHours(ec.workHoursShiftSystem_1StandardWorkingHour);
    setShift1StandardWorkHoursMinutes(ec.workHoursShiftSystem_1StandardWorkingMinute);

    setShift2StartTimeHours(ec.workHoursShiftSystem_2StartTimeHour);
    setShift2StartTimeMinutes(ec.workHoursShiftSystem_2StartTimeMinute);
    setShift2EndTimeHours(ec.workHoursShiftSystem_2EndTimeHour);
    setShift2EndTimeMinutes(ec.workHoursShiftSystem_2EndTimeMinute);
    setShift2ApplicableDate(ec.workHoursShiftSystem_2ApplicationDate ?? "");
    setShift2ApplicableDateTrans(transHandler.getTranslation("workHoursShiftSystem_2ApplicationDate"));
    setShift2StandardWorkHoursHours(ec.workHoursShiftSystem_2StandardWorkingHour);
    setShift2StandardWorkHoursMinutes(ec.workHoursShiftSystem_2StandardWorkingMinute);

    setShift3StartTimeHours(ec.workHoursShiftSystem_3StartTimeHour);
    setShift3StartTimeMinutes(ec.workHoursShiftSystem_3StartTimeMinute);
    setShift3EndTimeHours(ec.workHoursShiftSystem_3EndTimeHour);
    setShift3EndTimeMinutes(ec.workHoursShiftSystem_3EndTimeMinute);
    setShift3ApplicableDate(ec.workHoursShiftSystem_3ApplicationDate ?? "");
    setShift3ApplicableDateTrans(transHandler.getTranslation("workHoursShiftSystem_3ApplicationDate"));
    setShift3StandardWorkHoursHours(ec.workHoursShiftSystem_3StandardWorkingHour);
    setShift3StandardWorkHoursMinutes(ec.workHoursShiftSystem_3StandardWorkingMinute);

    setBreakTime(ec.workHoursBreakTime);
    setNormalWorkHoursWeeklyHours(ec.workHoursWeeklyNormalWorkingHour);
    setNormalWorkHoursWeeklyMinutes(ec.workHoursWeeklyNormalWorkingMinute);
    setNormalWorkHoursMonthlyHours(ec.workHoursMonthlyNormalWorkingHour);
    setNormalWorkHoursMonthlyMinutes(ec.workHoursMonthlyNormalWorkingMinute);
    setNormalWorkHoursAnnualHours(ec.workHoursAnnualNormalWorkingHour);
    setNormalWorkHoursAnnualMinutes(ec.workHoursAnnualNormalWorkingMinute);
    setNormalWorkDaysWeeklyJa(
        formatDecimalString(ec.workHoursWeeklyNormalWorkingDay_2) ?
            `${formatDecimalString(ec.workHoursWeeklyNormalWorkingDay_1)}〜${formatDecimalString(ec.workHoursWeeklyNormalWorkingDay_2)}` :
            formatDecimalString(ec.workHoursWeeklyNormalWorkingDay_1)
    );
    setNormalWorkDaysWeeklyEn(formatDecimalString(ec.workHoursWeeklyNormalWorkingDay_2) ?
        `${formatDecimalString(ec.workHoursWeeklyNormalWorkingDay_1)}~${formatDecimalString(ec.workHoursWeeklyNormalWorkingDay_2)}` :
        formatDecimalString(ec.workHoursWeeklyNormalWorkingDay_1)
    );
    setNormalWorkDaysMonthly(formatDecimalString(ec.workHoursMonthlyNormalWorkingDay));
    setNormalWorkDaysAnnually(formatDecimalString(ec.workHoursAnnualNormalWorkingDay));
    setNotesOnWork(
      transHandler.getEditableTranslationInJa(EditableTranslationKind.WorkHoursNote)
    );
    setNotesOnWorkTrans(
      transHandler.getEditableTranslation(EditableTranslationKind.WorkHoursNote)
    );
  }, [ec]);
  
  return (
    <Section>
      <SectionHeadingContainer>
        <span>IV.</span> 
        <Segment>
          <span>労働時間等</span><br/>
          <span>{tFixed("working_hours_etc")}</span>
        </Segment>
      </SectionHeadingContainer>
      <Indent>
        <ListBlocksContainer>
          <ListBlock>
            <ListBlockIndex>1.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <span>始業・終業の時刻等 </span><br/>
                <span>{tFixed("start_end_times")}</span>
              </Segment>
              <ListBlock>
                <ListBlockIndex>(1)</ListBlockIndex>
                <ListBlockContent>
                  <Segment>
                    <Row>
                      <Item20>
                        <Row>
                          <Item5>始業</Item5>
                          <Item5>（　</Item5>
                            { (workStartTimeHours != null || 
                               workStartTimeMinutes != null) &&
                              <Item5>
                                <InputValue>
                                  {workStartTimeHours}時{displayWorkStartTimeMinutes}分
                                </InputValue>
                              </Item5>
                            }
                          <Item5>　）</Item5>
                        </Row>
                      </Item20>
                      <Item20>
                        <Row>
                          <Item5>終業</Item5>
                          <Item5>（　</Item5>
                            { (workEndTimeHours != null || 
                               workEndTimeMinutes != null) &&
                              <Item5>
                                <InputValue>
                                  {workEndTimeHours}時{displayWorkEndTimeMinutes}分
                                </InputValue>
                              </Item5>
                            }
                          <Item5>　）</Item5>
                        </Row>
                      </Item20>
                      <Item20>
                        <Row>
                          <Item10>(１日の所定労働時間</Item10>
                          { (workHourPerDayHours != null || 
                             workHourPerDayMinutes != null) &&
                            <Item5>
                              <InputValue>
                                {workHourPerDayHours}時間{displayWorkHourPerDayMinutes}分
                              </InputValue>
                            </Item5>
                          }
                          <Item5>)</Item5>
                        </Row>
                      </Item20>
                    </Row>
                    <Row>
                      <Item20>
                        <Row>
                          <Trans
                            i18nKey={getTKey("working_start_time")}
                            tOptions={tOptions}
                            values={{
                              hour: workStartTimeHours,
                              min: displayWorkStartTimeMinutes
                            }}
                            components={{
                              seg: <Item5/>,
                              hour: <WorkingStartHour/>,
                              hour_unit: <WorkingStartHourUnit/>,
                              min: <WorkingStartMinute/>,
                              min_unit: <WorkingStartMinuteUnit/>
                            }}
                          />
                        </Row>
                      </Item20>
                      <Item20>
                        <Row>
                          <Trans 
                            i18nKey={getTKey("working_end_time")}
                            tOptions={tOptions}
                            values={{
                              hour: workEndTimeHours,
                              min: displayWorkEndTimeMinutes
                            }}
                            components={{
                              seg: <Item5/>,
                              hour: <WorkingEndHour/>,
                              hour_unit: <WorkingEndHourUnit/>,
                              min: <WorkingEndMinute/>,
                              min_unit: <WorkingEndMinuteUnit/>
                            }}
                          />
                        </Row>
                      </Item20>
                      <Item20>
                        <Row>
                          <Trans
                            i18nKey={getTKey("daily_working_hours")}
                            tOptions={tOptions}
                            values={{
                              hour: workHourPerDayHours,
                              min: displayWorkHourPerDayMinutes
                            }}
                            components={{
                              desc: <Item10/>,
                              seg: <Item5/>,
                              hour: <WorkingHourPerDayHours/>,
                              hour_unit: <WorkingHourPerDayHoursUnit/>,
                              min: <WorkingHourPerDayMinutes/>,
                              min_unit: <WorkingHourPerDayMinutesUnit/>
                            }}
                          />
                        </Row>
                      </Item20>
                    </Row>
                  </Segment>
                </ListBlockContent>
              </ListBlock>

              <ListBlock>
                <ListBlockIndex>(2)</ListBlockIndex>
                <ListBlockContent>
                  <Segment>
                    <span>【次の制度が労働者に適用される場合】</span><br/>
                    <span>{tFixed("applicable_systems")}</span>
                  </Segment>
                  <Segment>
                    <Column>
                      <CheckMark 
                        checked={variableWorkingHours}
                      >
                        <Item5>変形労働時間制：（</Item5>
                        <Item5><InputValue>{variableWorkingHoursUnitInJa}</InputValue></Item5>
                        <Item5>）単位の変形労働時間制</Item5>
                      </CheckMark>
                      <CheckMark 
                        checked={variableWorkingHours}
                      >
                        <Trans
                          i18nKey={getTKey("variable_working_hours_system")}
                          tOptions={tOptions}
                          values={{
                            unit: variableWorkingHoursUnitInTrans
                          }}
                          components={{
                            seg: <Item5/>,
                            unit: <VariableWorkingHoursUnit/>
                          }}
                        />
                      </CheckMark>
                    </Column>
                  </Segment>
                  <Segment>
                    <span>※ １年単位の変形労働時間制を採用している場合には，乙が十分に理解できる言語を併記した年間カレンダーの写し及び労働基準監督署へ届け出た変形労働時間制に関する協定書の写しを添付する。</span><br/>
                    <span>{tFixed("yearly_flexible_hours_policy")}</span>
                  </Segment>
                  <Segment>
                    <Column>
                      <CheckMark 
                        checked={shiftSystem}
                      >
                        交代制として，次の勤務時間の組合せによる。
                      </CheckMark>
                      <CheckMark 
                        checked={shiftSystem}
                      >
                        {tFixed("shift_system")}
                      </CheckMark>
                    </Column>
                  </Segment>
                  <Segment>
                    <Column5>
                      <FlexRowColumn>
                        <span>始業（
                          { (shift1StartTimeHours != null || 
                             shift1StartTimeMinutes != null) &&
                          <InputValue>{shift1StartTimeHours}時{displayShift1StartTimeMinutes}分</InputValue>
                          }
                          ）
                        </span>
                        <span>終業（
                          { (shift1EndTimeHours != null || 
                             shift1EndTimeMinutes != null) &&
                          <InputValue>{shift1EndTimeHours}時{displayShift1EndTimeMinutes}分</InputValue>
                          }
                          ）
                        </span>
                        <div>
                          <Item5>（適用日</Item5>
                          <Item5>
                            <InputValue>
                              {shift1ApplicableDate}
                            </InputValue>
                          </Item5>
                          <Item5>,</Item5>
                          <Item5>１日の所定労働時間</Item5>
                          <Item5>
                            { (shift1StandardWorkHoursHours != null || 
                               shift1StandardWorkHoursMinutes != null) &&
                            <InputValue>
                              {shift1StandardWorkHoursHours}時{displayShift1StandardWorkHoursMinutes}分
                            </InputValue>
                            }
                            ）
                          </Item5>
                        </div>
                      </FlexRowColumn>
                      <FlexRowColumn>
                        <span>始業（
                          { (shift2StartTimeHours != null || 
                             shift2StartTimeMinutes != null) &&
                            <InputValue>{shift2StartTimeHours}時{displayShift2StartTimeMinutes}分</InputValue>
                          }  
                          ）
                        </span>
                        <span>終業（
                          { (shift2EndTimeHours != null || 
                             shift2EndTimeMinutes != null) &&
                            <InputValue>{shift2EndTimeHours}時{displayShift2EndTimeMinutes}分</InputValue>
                          }
                          ）</span>
                        <div>
                          <Item5>（適用日</Item5>
                          <Item5>
                            <InputValue>
                              {shift2ApplicableDate}
                            </InputValue>
                          </Item5>
                          <Item5>,</Item5>
                          <Item5>１日の所定労働時間</Item5>
                          <Item5>
                            { (shift2StandardWorkHoursHours != null || 
                               shift2StandardWorkHoursMinutes != null) &&
                            <InputValue>
                              {shift2StandardWorkHoursHours}時{displayShift2StandardWorkHoursMinutes}分
                            </InputValue>
                            }
                            ）  
                          </Item5>
                        </div>
                      </FlexRowColumn>
                      <FlexRowColumn>
                        <span>始業（
                          { (shift3StartTimeHours != null || 
                             shift3StartTimeMinutes != null) &&
                            <InputValue>{shift3StartTimeHours}時{displayShift3StartTimeMinutes}分</InputValue>
                          }
                          ）
                        </span>
                        <span>終業（
                          { (shift3EndTimeHours != null || 
                             shift3EndTimeMinutes != null) &&
                            <InputValue>{shift3EndTimeHours}時{displayShift3EndTimeMinutes}分</InputValue>
                          }                          
                          ）
                        </span>
                        <div>
                          <Item5>（適用日</Item5>
                          <Item5>
                            <InputValue>
                              {shift3ApplicableDate}
                            </InputValue>
                          </Item5>
                          <Item5>,</Item5>
                          <Item5>１日の所定労働時間</Item5>
                          <Item5>
                            { (shift3StandardWorkHoursHours != null || 
                               shift3StandardWorkHoursMinutes != null) &&
                              <InputValue>
                                {shift3StandardWorkHoursHours}時{displayShift3StandardWorkHoursMinutes}分
                              </InputValue>
                            }
                            ）  
                          </Item5>
                        </div>
                      </FlexRowColumn>
                      <Column5>
                        <Segment>
                          <FlexRowColumn>
                            <Item5>
                              <Trans
                                i18nKey={getTKey("shift_start_time")}
                                tOptions={tOptions}
                                values={{
                                  hour: shift1StartTimeHours,
                                  min: displayShift1StartTimeMinutes
                                }}
                                components={{
                                  seg: <Item5/>,
                                  hour: <Shift1StartHour/>,
                                  hour_unit: <Shift1StartHourUnit/>,
                                  min: <Shift1StartMinute/>,
                                  min_unit: <Shift1StartMinuteUnit/>
                                }}
                              />
                            </Item5>
                            <span>
                              <Trans
                                i18nKey={getTKey("shift_end_time")}
                                tOptions={tOptions}
                                values={{
                                  hour: shift1EndTimeHours,
                                  min: displayShift1EndTimeMinutes
                                }}
                                components={{
                                  seg: <Item5/>,
                                  hour: <Shift1EndHour/>,
                                  hour_unit: <Shift1EndHourUnit/>,
                                  min: <Shift1EndMinute/>,
                                  min_unit: <Shift1EndMinuteUnit/>
                                }}
                              />
                            </span>
                          </FlexRowColumn>
                          <div>
                            <Trans
                              i18nKey={getTKey("shift_applicable_date_and_time")}
                              tOptions={{ lng: transLang}}
                              values={{
                                date: shift1ApplicableDateTrans,
                                hour: shift1StandardWorkHoursHours,
                                min: displayShift1StandardWorkHoursMinutes
                              }}
                              components={{
                                seg: <Item5/>,
                                date: <InputValue/>,
                                hour: <Shift1StandardWorkingTimeHour/>,
                                hour_unit: <Shift1StandardWorkingTimeHourUnit/>,
                                min: <Shift1StandardWorkingTimeMinute/>,
                                min_unit: <Shift1StandardWorkingTimeMinuteUnit/>
                              }}
                            />
                          </div>
                        </Segment>
                        <Segment>
                          <FlexRowColumn>
                            <Item5>
                              <Trans
                                i18nKey={getTKey("shift_start_time")}
                                tOptions={tOptions}
                                values={{
                                  hour: shift2StartTimeHours,
                                  min: displayShift2StartTimeMinutes
                                }}
                                components={{
                                  seg: <Item5/>,
                                  hour: <Shift2StartHour/>,
                                  hour_unit: <Shift2StartHourUnit/>,
                                  min: <Shift2StartMinute/>,
                                  min_unit: <Shift2StartMinuteUnit/>
                                }}
                              />
                            </Item5>
                            <span>
                              <Trans
                                i18nKey={getTKey("shift_end_time")}
                                tOptions={tOptions}
                                values={{
                                  hour: shift2EndTimeHours,
                                  min: displayShift2EndTimeMinutes
                                }}
                                components={{
                                  seg: <Item5/>,
                                  hour: <Shift2EndHour/>,
                                  hour_unit: <Shift2EndHourUnit/>,
                                  min: <Shift2EndMinute/>,
                                  min_unit: <Shift2EndMinuteUnit/>
                                }}
                              />
                            </span>
                          </FlexRowColumn>
                          <div>
                            <Trans
                              i18nKey={getTKey("shift_applicable_date_and_time")}
                              tOptions={tOptions}
                              values={{
                                date: shift2ApplicableDateTrans,
                                hour: shift2StandardWorkHoursHours,
                                min: displayShift2StandardWorkHoursMinutes
                              }}
                              components={{
                                seg: <Item5/>,
                                date: <InputValue/>,
                                hour: <Shift2StandardWorkingTimeHour/>,
                                hour_unit: <Shift2StandardWorkingTimeHourUnit/>,
                                min: <Shift2StandardWorkingTimeMinute/>,
                                min_unit: <Shift2StandardWorkingTimeMinuteUnit/>
                              }}
                            />
                          </div>
                        </Segment>
                        <Segment>
                          <FlexRowColumn>
                            <Item5>
                              <Trans
                                i18nKey={getTKey("shift_start_time")}
                                tOptions={tOptions}
                                values={{
                                  hour: shift3StartTimeHours,
                                  min: displayShift3StartTimeMinutes
                                }}
                                components={{
                                  seg: <Item5/>,
                                  hour: <Shift3StartHour/>,
                                  hour_unit: <Shift3StartHourUnit/>,
                                  min: <Shift3StartMinute/>,
                                  min_unit: <Shift3StartMinuteUnit/>
                                }}
                              />
                            </Item5>
                            <span>
                              <Trans
                                i18nKey={getTKey("shift_end_time")}
                                tOptions={tOptions}
                                values={{
                                  hour: shift3EndTimeHours,
                                  min: displayShift3EndTimeMinutes
                                }}
                                components={{
                                  seg: <Item5/>,
                                  hour: <Shift3EndHour/>,
                                  hour_unit: <Shift3EndHourUnit/>,
                                  min: <Shift3EndMinute/>,
                                  min_unit: <Shift3EndMinuteUnit/>
                                }}
                              />
                            </span>
                          </FlexRowColumn>
                          <div>
                            <Trans
                              i18nKey={getTKey("shift_applicable_date_and_time")}
                              tOptions={tOptions}
                              values={{
                                date: shift3ApplicableDateTrans,
                                hour: shift3StandardWorkHoursHours,
                                min: displayShift3StandardWorkHoursMinutes
                              }}
                              components={{
                                seg: <Item5/>,
                                date: <InputValue/>,
                                hour: <Shift3StandardWorkingTimeHour/>,
                                hour_unit: <Shift3StandardWorkingTimeHourUnit/>,
                                min: <Shift3StandardWorkingTimeMinute/>,
                                min_unit: <Shift3StandardWorkingTimeMinuteUnit/>
                              }}
                            />
                          </div>
                        </Segment>
                      </Column5>
                    </Column5>
                  </Segment>
                </ListBlockContent>
              </ListBlock>
            </ListBlockContent>
          </ListBlock>
          
          <ListBlock>
            <ListBlockIndex>2.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <span>休憩時間 (　
                  <Item10>
                    <InputValue>{breakTime || "　"}</InputValue>
                  </Item10>
                  <span>
                    分)
                  </span>
                </span><br/>
                <Trans
                  i18nKey={getTKey("break_time")}
                  tOptions={tOptions}
                  values={{ 
                    min: breakTime
                  }}
                  components={{
                    seg: <Item10/>,
                    min: <BreakTimeMin/>,
                    min_unit: <span/>
                  }}
                />
              </Segment>
            </ListBlockContent>
          </ListBlock>

          <ListBlock>
            <ListBlockIndex>3.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <FlexRowColumn>
                  <Item10>
                    所定労働時間数
                  </Item10>
                  <Item10>
                    ①週（　
                      { (normalWorkHoursWeeklyHours != null || 
                         normalWorkHoursWeeklyMinutes != null) &&
                        <InputValue>{normalWorkHoursWeeklyHours}時間{displayNormalWorkHoursWeeklyMinutes}分</InputValue>
                      }
                      　）
                  </Item10>
                  <Item10>
                    ②月（　
                      { (normalWorkHoursMonthlyHours != null || 
                         normalWorkHoursMonthlyMinutes != null) &&
                        <InputValue>{normalWorkHoursMonthlyHours}時間{displayNormalWorkHoursMonthlyMinutes}分</InputValue>
                      }
                      　）
                  </Item10>
                  <Item10>
                    ③年（　
                      { (normalWorkHoursAnnualHours != null || 
                         normalWorkHoursAnnualMinutes != null) &&
                        <InputValue>{normalWorkHoursAnnualHours}時間{displayNormalWorkHoursAnnualMinutes}分</InputValue>
                      }
                    　）
                  </Item10>
                </FlexRowColumn>
                <FlexRowColumn>
                  <Item10>
                    {tFixed("normal_working_hours")}
                  </Item10>
                  <Item10>
                    <Trans
                      i18nKey={getTKey("normal_working_hours_weekly")}
                      tOptions={tOptions}
                      values={{
                        hour: normalWorkHoursWeeklyHours,
                        min: displayNormalWorkHoursWeeklyMinutes
                      }}
                      components={{
                        hour: <NormalWorkingHoursWeeklyHour/>,
                        hour_unit: <NormalWorkingHoursWeeklyHourUnit/>,
                        min: <NormalWorkingHoursWeeklyMinute/>,
                        min_unit: <NormalWorkingHoursWeeklyMinuteUnit/>
                      }}
                    />
                  </Item10>
                  <Item10>
                    <Trans
                      i18nKey={getTKey("normal_working_hours_monthly")}
                      tOptions={tOptions}
                      values={{
                        hour: normalWorkHoursMonthlyHours,
                        min: displayNormalWorkHoursMonthlyMinutes
                      }}
                      components={{
                        hour: <NormalWorkingHoursMonthlyHour/>,
                        hour_unit: <NormalWorkingHoursMonthlyHourUnit/>,
                        min: <NormalWorkingHoursMonthlyMinute/>,
                        min_unit: <NormalWorkingHoursMonthlyMinuteUnit/>
                      }}
                    />
                  </Item10>
                  <Item10>
                    <Trans
                      i18nKey={getTKey("normal_working_hours_annually")}
                      tOptions={{ lng: transLang}}
                      values={{
                        hour: normalWorkHoursAnnualHours,
                        min: displayNormalWorkHoursAnnualMinutes
                      }}
                      components={{
                        hour: <NormalWorkingHoursAnnualHour/>,
                        hour_unit: <NormalWorkingHoursAnnualHourUnit/>,
                        min: <NormalWorkingHoursAnnualMinute/>,
                        min_unit: <NormalWorkingHoursAnnualMinuteUnit/>
                      }}
                    />
                  </Item10>
                </FlexRowColumn>
              </Segment>
            </ListBlockContent>
          </ListBlock>

          <ListBlock>
            <ListBlockIndex>4.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <FlexRowColumn>
                  <Item10>所定労働日数</Item10>
                  <Item10>①週（　<InputValue>{normalWorkDaysWeeklyJa}日</InputValue>　）</Item10>
                  <Item10>②月（　<InputValue>{normalWorkDaysMonthly}日</InputValue>　）</Item10>
                  <Item10>③年（　<InputValue>{normalWorkDaysAnnually}日</InputValue>　）</Item10>
                </FlexRowColumn>
                <FlexRowColumn>
                  <Item10>{tFixed("normal_working_days")}</Item10>
                  <Item10>
                    <Trans
                      i18nKey={getTKey("normal_working_days_weekly")}
                      tOptions={tOptions}
                      values={{ day: normalWorkDaysWeeklyEn }}
                      components={{
                        day: <InputValue/>
                      }}
                    />
                  </Item10>
                  <Item10>
                    <Trans
                      i18nKey={getTKey("normal_working_days_monthly")}
                      tOptions={tOptions}
                      values={{ day: normalWorkDaysMonthly }}
                      components={{
                        day: <InputValue/>
                      }}
                    />
                  </Item10>
                  <Item10>
                    <Trans
                      i18nKey={getTKey("normal_working_days_annually")}
                      tOptions={tOptions}
                      values={{ day: normalWorkDaysAnnually }}
                      components={{
                        day: <InputValue/>
                      }}
                    />
                  </Item10>                  
                </FlexRowColumn>
              </Segment>
            </ListBlockContent>
          </ListBlock>

          <ListBlock>
            <ListBlockIndex>5.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <FlexRowColumn>
                  <Item20>時間外労働の有無</Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.workHoursOvertimeWork === true}
                    >
                      有
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.workHoursOvertimeWork === false}
                    >
                      無
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>
                <FlexRowColumn>
                  <Item20>{tFixed("overtime_presence")}</Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.workHoursOvertimeWork === true}
                    >
                      {tFixed("yes")}
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.workHoursOvertimeWork === false}
                    >
                      {tFixed("no")}
                    </CheckMark>   
                  </Item20>
                </FlexRowColumn>
              </Segment>
            </ListBlockContent>  
          </ListBlock>          
          <BlockNoteContainer>
            <span>{notesOnWork}</span><br/>
            <span>{notesOnWorkTrans}</span>
          </BlockNoteContainer>
        </ListBlocksContainer>
      </Indent>

    </Section>
  )
}

export default WorkHoursSection;