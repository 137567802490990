import { Candidate } from "../../../../candidate/types/api";
import { EcContractRenewal, EcWagePaymentType } from "../../../../core/enums/employmentConditions";
import { Company } from "../../../../core/types/api";
import { JobContractPeriod, JobContractRenewal, JobSalaryType } from "../../../../job/enums/job";
import { Job } from "../../../../job/types/api";
import { EmploymentConditionExtended } from "../../../types";

type FormInitConfig = {
  job: Job;
  candidate: Candidate;
  company?: Company;
};

export const prepareInitializedEcFormData = ({
  job,
  candidate,
  company
}: FormInitConfig): Partial<EmploymentConditionExtended> => {
  const initVals: Partial<EmploymentConditionExtended> = {};

  /*** Employee section (被用者) ***/
  //氏名（英字）
  initVals.employeeEnFullName = [
    candidate?.enLastName ?? "",
    candidate?.enFirstName ?? "",
    candidate?.enMiddleName ?? "",
  ].join(" ").trim();

  //-----------------------------------------------------------------------

  /*** Employer section (被用者名) ***/
  //会社/機関名（日本語）
  //Since this field cannot be edited, old job posts cannot have a value in ecEmployerJaOrganizationName
  //For those cases, we use the company's name
  initVals.employerJaOrganizationName = 
    job.ecEmployerJaOrganizationName ?? company?.jaName ?? "";

  //会社/機関名（英字）
  initVals.employerEnOrganizationName = job.ecEmployerEnOrganizationName ?? "";

  //郵便番号
  initVals.employerPostalCode = job.ecEmployerPostalCode ?? "";

  //都道府県
  initVals.employerJaPrefecture = job.ecEmployerJaPrefecture ?? "";

  //市区
  initVals.employerJaCityWard = job.ecEmployerJaCityWard ?? "";

  //町村
  initVals.employerJaTown = job.ecEmployerJaTown ?? "";

  //番地
  initVals.employerJaAddressNumber = job.ecEmployerJaAddressNumber ?? "";

  //建物名等
  initVals.employerJaBuilding = job.ecEmployerJaBuilding ?? "";

  //都道府県（英字）
  initVals.employerEnPrefecture = job.ecEmployerEnPrefecture ?? "";

  //市区（英字）
  initVals.employerEnCityWard = job.ecEmployerEnCityWard ?? "";

  //町村（英字）
  initVals.employerEnTown = job.ecEmployerEnTown ?? "";

  //番地（英字）
  initVals.employerEnAddressNumber = job.ecEmployerEnAddressNumber ?? "";

  //建物名等（英字）
  initVals.employerEnBuilding = job.ecEmployerEnBuilding ?? "";

  //電話番号
  initVals.employerPhoneNumber = job.ecEmployerPhoneNumber ?? "";

  //代表者名（日本語）
  initVals.employerJaRepresentativeName = job.ecEmployerJaRepresentativeName ?? "";

  //代表者名（英字）
  initVals.employerEnRepresentativeName = job.ecEmployerEnRepresentativeName ?? "";

  //代表者役職
  initVals.employerJaRepresentativeTitle = job.ecEmployerJaRepresentativeTitle ?? "";

  //代表者役職（英字）
  initVals.employerEnRepresentativeTitle = job.ecEmployerEnRepresentativeTitle ?? "";

  //-----------------------------------------------------------------------

  /*** Contract period section (雇用契約期間) ***/
  //始期
  //[N/A]

  //終期
  //[N/A]

  //入国予定日
  //[N/A]

  //契約更新
  const contractRenewal = job.ecContractPeriodRenewal ?? job.contractRenewal;
  switch (contractRenewal) {
    case JobContractRenewal.Automatic:
      initVals.contractPeriodRenewal = EcContractRenewal.AutomaticRenewal;
      break;
    case JobContractRenewal.Possible:
      initVals.contractPeriodRenewal = EcContractRenewal.ConditionalRenewal;
      break;
    case JobContractRenewal.No:
      initVals.contractPeriodRenewal = EcContractRenewal.NoRenewal;
      break;

    case EcContractRenewal.AutomaticRenewal:
      initVals.contractPeriodRenewal = EcContractRenewal.AutomaticRenewal;
      break;
    case EcContractRenewal.ConditionalRenewal:
      initVals.contractPeriodRenewal = EcContractRenewal.ConditionalRenewal;
      break;
    case EcContractRenewal.NoRenewal:
      initVals.contractPeriodRenewal = EcContractRenewal.NoRenewal;
      break;
  }

  //契約更新の判断基準 - 契約期間満了時の業務量
  initVals.contractPeriodRenewalConditionWorkload =
    job.ecContractPeriodRenewalConditionWorkload;

  //契約更新の判断基準 - 労働者の勤務成績、態度
  initVals.contractPeriodRenewalConditionEmployeePerformance =
    job.ecContractPeriodRenewalConditionEmployeePerformance;

  //契約更新の判断基準 - 労働者の業務を遂行する能力
  initVals.contractPeriodRenewalConditionEmployeeAbility =
    job.ecContractPeriodRenewalConditionEmployeeAbility;

  //契約更新の判断基準 - 会社の経営状況
  initVals.contractPeriodRenewalConditionCompanySituation =
    job.ecContractPeriodRenewalConditionCompanySituation;

  //契約更新の判断基準 - 従事している業務の進捗状況
  initVals.contractPeriodRenewalConditionProgressSituation =
    job.ecContractPeriodRenewalConditionProgressSituation;

  //契約更新の判断基準 - その他
  initVals.contractPeriodRenewalConditionOther =
    job.ecContractPeriodRenewalConditionOther;

  //契約更新の判断基準（その他詳細）
  initVals.contractPeriodRenewalConditionOtherDetail =
    job.ecContractPeriodRenewalConditionOtherDetail;

  //更新上限の有無
  switch (job.contractPeriod) {
    case JobContractPeriod.FixedTerm:
      initVals.contractPeriodRenewalUpdateLimit = true;
      initVals.contractPeriodRenewalTotalContractPeriod = job.contractPeriodYears;
      break;
    case JobContractPeriod.Indefinite:
      initVals.contractPeriodRenewalUpdateLimit = false;
      break;
  }

  //無期雇用契約時の労働条件変更の有無
  //[N/A]

  //-----------------------------------------------------------------------

  /*** Place of employment section (就業の場所) ***/
  //雇用形態
  initVals.placeOfEmploymentContractType = job.ecPlaceOfEmploymentContractType;

  //事業者名（日本語）
  initVals.placeOfEmploymentJaOfficeName = job.ecPlaceOfEmploymentJaOfficeName;

  //事業者名（英字）
  initVals.placeOfEmploymentEnOfficeName = job.ecPlaceOfEmploymentEnOfficeName;

  //郵便番号
  initVals.placeOfEmploymentPostalCode = job.ecPlaceOfEmploymentPostalCode ?? "";

  //都道府県
  initVals.placeOfEmploymentJaPrefecture = job.ecPlaceOfEmploymentJaPrefecture ?? "";

  //市区
  initVals.placeOfEmploymentJaCityWard = job.ecPlaceOfEmploymentJaCityWard ?? "";

  //町村
  initVals.placeOfEmploymentJaTown = job.ecPlaceOfEmploymentJaTown ?? "";

  //番地
  initVals.placeOfEmploymentJaAddressNumber = 
    job.jaAddressNumber ??
    job.ecPlaceOfEmploymentJaAddressNumber ?? 
    "";

  //建物名等
  initVals.placeOfEmploymentJaBuilding = 
    job.jaBuildingName ??
    job.ecPlaceOfEmploymentJaBuilding ?? 
    "";

  //都道府県（英字）
  initVals.placeOfEmploymentEnPrefecture = job.ecPlaceOfEmploymentEnPrefecture ?? "";

  //市区（英字）
  initVals.placeOfEmploymentEnCityWard = job.ecPlaceOfEmploymentEnCityWard ?? "";

  //町村（英字）
  initVals.placeOfEmploymentEnTown = job.ecPlaceOfEmploymentEnTown ?? "";

  //番地（英字）
  initVals.placeOfEmploymentEnAddressNumber = 
    job.enAddressNumber ??
    job.ecPlaceOfEmploymentEnAddressNumber ?? 
    "";

  //建物名等（英字）
  initVals.placeOfEmploymentEnBuilding = 
    job.enBuildingName ??
    job.ecPlaceOfEmploymentEnBuilding ?? 
    "";

  //連絡先
  initVals.placeOfEmploymentPhoneNumber =
    job.ecPlaceOfEmploymentPhoneNumber ?? "";

  //雇入れ後の変更の可能性
  //[N/A]

  //事業所名（日本語）
  //[N/A]

  //事業所名（英字）
  //[N/A]

  //郵便番号
  //[N/A]

  //都道府県
  //[N/A]

  //市区
  //[N/A]

  //町村
  //[N/A]

  //番地
  //[N/A]

  //建物名等
  //[N/A]

  //都道府県（英字）
  //[N/A]

  //市区（英字）
  //[N/A]

  //町村（英字）
  //[N/A]

  //番地（英字）
  //[N/A]

  //建物名等（英字）
  //[N/A]

  //連絡先
  //[N/A]

  //-----------------------------------------------------------------------

  /*** Type of work section (業務内容) ***/
  //分野
  initVals.jobTypeId = job.jobTypeId;

  //業務区分
  //No need to set because the backend handles this

  //雇入れ後の変更の可能性
  //[N/A]

  //分野
  //[N/A]

  //業務区分
  //No need to set because the backend handles this

  //-----------------------------------------------------------------------

  /*** Work hours section (労働時間等) ***/
  //始業時刻 -（時）
  initVals.workHoursStartTimeHour = job.ecWorkHoursStartTimeHour;

  //始業時刻 -（分）
  initVals.workHoursStartTimeMinute = job.ecWorkHoursStartTimeMinute;

  //終業時刻 -（時）
  initVals.workHoursEndTimeHour = job.ecWorkHoursEndTimeHour;

  //終業時刻 -（分）
  initVals.workHoursEndTimeMinute = job.ecWorkHoursEndTimeMinute;

  //一日の所定労働時間数 -（時）
  initVals.workHoursNormalWorkingHour = job.basicWorkHoursPerDay;

  //一日の所定労働時間数 -（分）
  initVals.workHoursNormalWorkingMinute = job.basicWorkMinutesPerDay;

  //変形労働時間制
  initVals.workHoursVariableWorkingHoursSystem =
    job.ecWorkHoursVariableWorkingHoursSystem;

  //変形労働時間制（単位)
  initVals.workHoursVariableWorkingHoursSystemUnit =
    job.ecWorkHoursVariableWorkingHoursSystemUnit;

  //シフト制
  initVals.workHoursShiftSystem = job.ecWorkHoursShiftSystem;

  //シフト1（始業・終業時刻）- 始業時刻（時）
  initVals.workHoursShiftSystem_1StartTimeHour =
    job.ecWorkHoursShiftSystem_1StartTimeHour;

  //シフト1（始業・終業時刻）- 始業時刻（分）
  initVals.workHoursShiftSystem_1StartTimeMinute =
    job.ecWorkHoursShiftSystem_1StartTimeMinute;

  //シフト1（始業・終業時刻）- 終業時刻（時）
  initVals.workHoursShiftSystem_1EndTimeHour =
    job.ecWorkHoursShiftSystem_1EndTimeHour;

  //シフト1（始業・終業時刻）- 終業時刻（分）
  initVals.workHoursShiftSystem_1EndTimeMinute =
    job.ecWorkHoursShiftSystem_1EndTimeMinute;

  //シフト1（適用日）
  initVals.workHoursShiftSystem_1ApplicationDate =
    job.ecWorkHoursShiftSystem_1ApplicationDate;

  //シフト1（一日の所定労働時間）-（時）
  initVals.workHoursShiftSystem_1StandardWorkingHour =
    job.ecWorkHoursShiftSystem_1StandardWorkingHour;

  //シフト1（一日の所定労働時間）-（分）
  initVals.workHoursShiftSystem_1StandardWorkingMinute =
    job.ecWorkHoursShiftSystem_1StandardWorkingMinute;

  //シフト2（始業・終業時刻）- 始業時刻（時）
  initVals.workHoursShiftSystem_2StartTimeHour =
    job.ecWorkHoursShiftSystem_2StartTimeHour;

  //シフト2（始業・終業時刻）- 始業時刻（分）
  initVals.workHoursShiftSystem_2StartTimeMinute =
    job.ecWorkHoursShiftSystem_2StartTimeMinute;

  //シフト2（始業・終業時刻）- 終業時刻（時）
  initVals.workHoursShiftSystem_2EndTimeHour =
    job.ecWorkHoursShiftSystem_2EndTimeHour;

  //シフト2（始業・終業時刻）- 終業時刻（分）
  initVals.workHoursShiftSystem_2EndTimeMinute =
    job.ecWorkHoursShiftSystem_2EndTimeMinute;

  //シフト2（適用日）
  initVals.workHoursShiftSystem_2ApplicationDate =
    job.ecWorkHoursShiftSystem_2ApplicationDate;

  //シフト2（一日の所定労働時間）-（時）
  initVals.workHoursShiftSystem_2StandardWorkingHour =
    job.ecWorkHoursShiftSystem_2StandardWorkingHour;

  //シフト2（一日の所定労働時間）-（分）
  initVals.workHoursShiftSystem_2StandardWorkingMinute =
    job.ecWorkHoursShiftSystem_2StandardWorkingMinute;

  //シフト3（始業・終業時刻）- 始業時刻（時）
  initVals.workHoursShiftSystem_3StartTimeHour =
    job.ecWorkHoursShiftSystem_3StartTimeHour;

  //シフト3（始業・終業時刻）- 始業時刻（分）
  initVals.workHoursShiftSystem_3StartTimeMinute =
    job.ecWorkHoursShiftSystem_3StartTimeMinute;

  //シフト3（始業・終業時刻）- 終業時刻（時）
  initVals.workHoursShiftSystem_3EndTimeHour =
    job.ecWorkHoursShiftSystem_3EndTimeHour;

  //シフト3（始業・終業時刻）- 終業時刻（分）
  initVals.workHoursShiftSystem_3EndTimeMinute =
    job.ecWorkHoursShiftSystem_3EndTimeMinute;

  //シフト3（適用日）
  initVals.workHoursShiftSystem_3ApplicationDate =
    job.ecWorkHoursShiftSystem_3ApplicationDate;

  //シフト3（一日の所定労働時間）-（時）
  initVals.workHoursShiftSystem_3StandardWorkingHour =
    job.ecWorkHoursShiftSystem_3StandardWorkingHour;

  //シフト3（一日の所定労働時間）-（分）
  initVals.workHoursShiftSystem_3StandardWorkingMinute =
    job.ecWorkHoursShiftSystem_3StandardWorkingMinute;

  //休憩時間
  initVals.workHoursBreakTime = job.ecWorkHoursBreakTime;

  //所定労働時間数（週）- （時）
  initVals.workHoursWeeklyNormalWorkingHour =
    job.ecWorkHoursWeeklyNormalWorkingHour;

  //所定労働時間数（週）- （分）
  initVals.workHoursWeeklyNormalWorkingMinute =
    job.ecWorkHoursWeeklyNormalWorkingMinute;

  //所定労働時間数（月間）- （時）
  initVals.workHoursMonthlyNormalWorkingHour =
    job.ecWorkHoursMonthlyNormalWorkingHour;

  //所定労働時間数（月間）- （分）
  initVals.workHoursMonthlyNormalWorkingMinute =
    job.ecWorkHoursMonthlyNormalWorkingMinute;

  //所定労働時間数（年間）- （時）
  initVals.workHoursAnnualNormalWorkingHour =
    job.ecWorkHoursAnnualNormalWorkingHour;

  //所定労働時間数（年間）- （分）
  initVals.workHoursAnnualNormalWorkingMinute =
    job.ecWorkHoursAnnualNormalWorkingMinute;

  //所定労働時間数（週）
  initVals.workHoursWeeklyNormalWorkingHour =
    job.ecWorkHoursWeeklyNormalWorkingHour;

  //所定労働時間数（月間）
  initVals.workHoursMonthlyNormalWorkingHour =
    job.ecWorkHoursMonthlyNormalWorkingHour;

  //所定労働時間数（年間）
  initVals.workHoursAnnualNormalWorkingHour =
    job.ecWorkHoursAnnualNormalWorkingHour;

  //所定労働日数（週）_from
  initVals.workHoursWeeklyNormalWorkingDay_1 =
    job.ecWorkHoursWeeklyNormalWorkingDay_1;

  //所定労働日数（週）_to
  initVals.workHoursWeeklyNormalWorkingDay_2 =
      job.ecWorkHoursWeeklyNormalWorkingDay_2;

  //所定労働日数（月間）
  initVals.workHoursMonthlyNormalWorkingDay =
    job.ecWorkHoursMonthlyNormalWorkingDay;

  //所定労働日数（年間）
  initVals.workHoursAnnualNormalWorkingDay =
    job.ecWorkHoursAnnualNormalWorkingDay;

  //所定外労働時間
  initVals.workHoursOvertimeWork = job.ecWorkHoursOvertimeWork;

  //備考
  //No need to set because the backend handles this

  //-----------------------------------------------------------------------

  /*** Holidays section (休日) ***/
  //定例休日 - 月
  initVals.holidaysFixedDaysMonday = job.ecHolidaysFixedDaysMonday;

  //定例休日 - 火
  initVals.holidaysFixedDaysTuesday = job.ecHolidaysFixedDaysTuesday;

  //定例休日 - 水
  initVals.holidaysFixedDaysWednesday = job.ecHolidaysFixedDaysWednesday;

  //定例休日 - 木
  initVals.holidaysFixedDaysThursday = job.ecHolidaysFixedDaysThursday;

  //定例休日 - 金
  initVals.holidaysFixedDaysFriday = job.ecHolidaysFixedDaysFriday;

  //定例休日 - 土
  initVals.holidaysFixedDaysSaturday = job.ecHolidaysFixedDaysSaturday;

  //定例休日 - 日
  initVals.holidaysFixedDaysSunday = job.ecHolidaysFixedDaysSunday;

  //日本の国民の祝日
  initVals.holidaysNationalHolidaysOfJapan =
    job.ecHolidaysNationalHolidaysOfJapan;

  //その他の定例休日
  //No need to set because the backend handles this

  //年間合計休日日数
  initVals.holidaysAnnualNumberOfHolidays = job.averageAnnualDaysOff;

  //非定例休日（単位）
  initVals.holidaysIrregularDaysUnit = job.ecHolidaysIrregularDaysUnit;

  //非定例休日（日数）
  initVals.holidaysIrregularDaysNumberOfDays =
    job.ecHolidaysIrregularDaysNumberOfDays;

  //その他の非定例休日
  //No need to set because the backend handles this

  //備考
  //No need to set because the backend handles this

  //-----------------------------------------------------------------------

  /*** Vacation section (休暇) ***/
  //年次有給休暇（6ヶ月勤務後）
  initVals.leavesAnnualPaidLeavesAfter_6MonthsDays =
    job.ecLeavesAnnualPaidLeavesAfter_6MonthsDays;

  //年次有給休暇（6ヶ月未満）
  initVals.leavesAnnualPaidLeavesBefore_6Months =
    job.ecLeavesAnnualPaidLeavesBefore_6Months;

  //6ヶ月未満の付与期間
  initVals.leavesAnnualPaidLeavesBefore_6MonthsMonths =
    job.ecLeavesAnnualPaidLeavesBefore_6MonthsMonths;

  //6ヶ月未満の付与日数
  initVals.leavesAnnualPaidLeavesBefore_6MonthsDays =
    job.ecLeavesAnnualPaidLeavesBefore_6MonthsDays;

  //その他の休暇（有給）
  //No need to set because the backend handles this

  //その他の休暇（無給）
  //No need to set because the backend handles this

  //備考
  //No need to set because the backend handles this

  //-----------------------------------------------------------------------

  /*** Compensation section (賃金) ***/
  //基本賃金（支給形態）
  //[N/A] - handled in another section

  //基本賃金（金額）
  //[N/A] - handled in another section

  //諸手当（時間外労働の割増賃金を除く）
  //No need to set because the backend handles this


  /* Note:
     The following default values come from the placeholder values.
    所定時間外（法定超月60時間以内）: 25 %
    所定時間外（法定超月60時間超）: 50 %
    所定時間外（所定超）: 25 %
    休日（法定）: 35 %
    休日（法定外）: 25 %
    深夜: 25 %
  */

  //所定時間外（法定超月60時間以内）
  initVals.compensationExtraWageRatesOvertimeAboveLegalLte_60Hs =
    job.ecCompensationExtraWageRatesOvertimeAboveLegalLte_60Hs ?? 25;

  //所定時間外（法定超月60時間超）
  initVals.compensationExtraWageRatesOvertimeAboveLegalGt_60Hs =
    job.ecCompensationExtraWageRatesOvertimeAboveLegalGt_60Hs ?? 50;

  //所定時間外（所定超）
  initVals.compensationExtraWageRatesOvertimeAboveNormal =
    job.ecCompensationExtraWageRatesOvertimeAboveNormal ?? 25;

  //休日（法定）
  initVals.compensationExtraWageRatesHolidayLegal =
    job.ecCompensationExtraWageRatesHolidayLegal ?? 35;

  //休日（法定外）
  initVals.compensationExtraWageRatesHolidayOtherThanLegal =
    job.ecCompensationExtraWageRatesHolidayOtherThanLegal ?? 25;

  //深夜
  initVals.compensationExtraWageRatesMidnight =
    job.ecCompensationExtraWageRatesMidnight ?? 25;

  //締切日/支払日1
  initVals.compensationPayrollCutOffDate_1 = job.ecCompensationPayrollCutOffDate_1;
  initVals.compensationPayrollMonth_1 = job.ecCompensationPayrollMonth_1;
  initVals.compensationPayrollDate_1 = job.ecCompensationPayrollDate_1;

  //支払日/支払日2
  initVals.compensationPayrollCutOffDate_2 = job.ecCompensationPayrollCutOffDate_2;
  initVals.compensationPayrollMonth_2 = job.ecCompensationPayrollMonth_2;
  initVals.compensationPayrollDate_2 = job.ecCompensationPayrollDate_2;

  //支払方法
  initVals.compensationPaymentMethod = job.ecCompensationPaymentMethod;

  //控除
  initVals.compensationDeductions = job.ecDeductionsTotalDeductions > 0;

  //昇給
  initVals.compensationWageRaise = job.salaryRaise;

  //昇給の時期・金額等
  initVals.compensationWageRaiseTimeAmountEtc =
    job.ecCompensationWageRaiseTimeAmountEtc;

  //賞与
  initVals.compensationBonus = job.bonus;

  //賞与の支給回数（年間）
  initVals.compensationBonusNumberOfPaymentsPerYear =
    job.numberOfBonusPaymentsPerYear;

  //賞与の支給時期
  initVals.bonusMonths = job.bonusMonths;

  //賞与の金額等
  initVals.compensationBonusAmountEtc = job.ecCompensationBonusAmountEtc;

  //退職金
  initVals.compensationSeverancePayment = job.ecCompensationSeverancePayment;

  //退職金の条件・金額等
  initVals.compensationSeverancePaymentTimeAmountEtc =
    job.ecCompensationSeverancePaymentTimeAmountEtc;

  //休業手当
  initVals.compensationLeaveAllowance = job.ecCompensationLeaveAllowance;

  //休業手当の支給額（平均賃金の割合）
  initVals.compensationLeaveAllowanceRate =
    job.ecCompensationLeaveAllowanceRate;

  //-----------------------------------------------------------------------

  /*** Resignation section (退職に関する事項) ***/
  //自己都合退職の手続
  initVals.resignationProcedureForResignationForPersonalReasons =
    job.ecResignationProcedureForResignationForPersonalReasons;

  //解雇の事由及び手続
  //[N/A] - There is no input field

  //備考
  //No need to set because the backend handles this

  /*** Other section (その他) ***/
  //社会保険等の加入状況
  initVals.otherSocialInsuranceCoverageEmployeesPension =
    job.ecOtherSocialInsuranceCoverageEmployeesPension;

  initVals.otherSocialInsuranceCoverageHealthInsurance =
    job.ecOtherSocialInsuranceCoverageHealthInsurance;

  initVals.otherSocialInsuranceCoverageEmploymentInsurance =
    job.ecOtherSocialInsuranceCoverageEmploymentInsurance;

  initVals.otherSocialInsuranceCoverageAccidentInsurance =
    job.ecOtherSocialInsuranceCoverageAccidentInsurance;

  initVals.otherSocialInsuranceCoverageNationalPension =
    job.ecOtherSocialInsuranceCoverageNationalPension;

  initVals.otherSocialInsuranceCoverageNationalHealthInsurance =
    job.ecOtherSocialInsuranceCoverageNationalHealthInsurance;

  initVals.otherSocialInsuranceCoverageOther =
    job.ecOtherSocialInsuranceCoverageOther;

  //社会保険等の加入状況（その他）
  //No need to set because the backend handles this

  //雇入れ時の健康診断
  initVals.otherMedicalExaminationAtTheTimeOfHiring =
    job.ecOtherMedicalExaminationAtTheTimeOfHiring;

  //初回の定期健康診断
  initVals.otherFirstPeriodicMedicalExamination =
    job.ecOtherFirstPeriodicMedicalExamination;

  //2回目以降の定期健康診断
  initVals.otherSecondAndSubsequentPeriodicMedicalExaminations =
    job.ecOtherSecondAndSubsequentPeriodicMedicalExaminations;

  //雇用管理の改善等に関する相談窓口（部署名）
  initVals.otherDepartmentConsultationEmploymentManagementJa =
    job.ecOtherDepartmentConsultationEmploymentManagementJa;

  //雇用管理の改善等に関する相談窓口（担当者職氏名）
  initVals.otherManagerConsultationEmploymentManagementJa =
    job.ecOtherManagerConsultationEmploymentManagementJa;

  //雇用管理の改善等に関する相談窓口（連絡先電話番号）
  initVals.otherContactConsultationEmploymentManagement = 
    job.ecOtherContactConsultationEmploymentManagement;

  //雇用管理の改善等に関する相談窓口（部署名、英字）
  initVals.otherDepartmentConsultationEmploymentManagementEn =
    job.ecOtherDepartmentConsultationEmploymentManagementEn;

  //雇用管理の改善等に関する相談窓口（担当者職氏名、英字）
  initVals.otherManagerConsultationEmploymentManagementEn =
    job.ecOtherManagerConsultationEmploymentManagementEn;

  //帰国旅費の負担
  //[N/A] - There is no input field

  //就業規則確認方法
  //No need to set because the backend handles this

  //-----------------------------------------------------------------------

  /*** Base wage section (基本賃金) ***/
  //支給形態
  switch (job.salaryType) {
    case JobSalaryType.Monthly:
      initVals.baseWageType = EcWagePaymentType.Monthly;
      break;
    case JobSalaryType.Daily:
      initVals.baseWageType = EcWagePaymentType.Daily;
      break;
    case JobSalaryType.Hourly:
      initVals.baseWageType = EcWagePaymentType.Hourly;
      break;
  }

  //金額
  initVals.baseWageAmount = job.grossSalary;

  //1時間当たりの金額（※月給・日給の場合）
  initVals.baseWageAmountPerHour = job.hourlyDailySalary;

  //1か月当たりの金額（※日給・時給の場合）
  initVals.baseWageAmountPerMonth = job.grossSalary;

  /*** Benefit section (手当) ***/
  //手当1（名称）
  //No need to set because the backend handles this

  //手当1（金額）
  initVals.benefitsBenefit_1Amount = job.ecBenefitsBenefit_1Amount;

  //手当1（計算方法）
  //No need to set because the backend handles this

  //手当2（名称）
  //No need to set because the backend handles this

  //手当2（金額）
  initVals.benefitsBenefit_2Amount = job.ecBenefitsBenefit_2Amount;

  //手当2（計算方法）
  //No need to set because the backend handles this

  //手当3（名称）
  //No need to set because the backend handles this

  //手当3（金額）
  initVals.benefitsBenefit_3Amount = job.ecBenefitsBenefit_3Amount;

  //手当3（計算方法）
  //No need to set because the backend handles this

  //手当4（名称）
  //No need to set because the backend handles this

  //手当4（金額）
  initVals.benefitsBenefit_4Amount = job.ecBenefitsBenefit_4Amount;

  //手当4（計算方法）
  //No need to set because the backend handles this

  //固定残業代（名称）
  initVals.benefitsFixedOvertimePayName = job.ecBenefitsFixedOvertimePayName;

  //固定残業代（金額）
  if (job.ecBenefitsFixedOvertimePayAmount != null) 
    initVals.benefitsFixedOvertimePayAmount = job.ecBenefitsFixedOvertimePayAmount;

  //固定残業代（支給要件）
  if (job.ecBenefitsFixedOvertimePayPaymentRequirement_1 != null)
    initVals.benefitsFixedOvertimePayPaymentRequirement_1 = Number(job.ecBenefitsFixedOvertimePayPaymentRequirement_1);

  if (job.ecBenefitsFixedOvertimePayPaymentRequirement_2 != null)
    initVals.benefitsFixedOvertimePayPaymentRequirement_2 = Number(job.ecBenefitsFixedOvertimePayPaymentRequirement_2);

  //1か月当たりの支払概算額（基本賃金+時間外割増を除く諸手当）
  initVals.estimatedPaymentPerMonth = job.ecEstimatedPaymentPerMonth;

  //-----------------------------------------------------------------------

  /*** Deduction section（控除） ***/
  //税金
  initVals.deductionsTaxes = job.estimatedIncomeTax;

  //社会保険料
  initVals.deductionsSocialInsurancePremiums = job.estimatedSocialInsurance;

  //雇用保険料
  initVals.deductionsEmploymentInsurancePremiums =
    job.ecDeductionsEmploymentInsurancePremiums;

  //食費
  initVals.deductionsFoodExpenses = job.ecDeductionsFoodExpenses;

  //居住費
  initVals.deductionsHousingExpenses = job.dormitoryFee;

  //その他控除1（名称）
  //No need to set because the backend handles this

  //その他控除1（金額）
  initVals.deductionsOtherDeductions_1Amount =
    job.ecDeductionsOtherDeductions_1Amount;

  //その他控除2（名称）
  //No need to set because the backend handles this

  //その他控除2（金額）
  initVals.deductionsOtherDeductions_2Amount =
    job.ecDeductionsOtherDeductions_2Amount;

  //その他控除3（名称）
  //No need to set because the backend handles this

  //その他控除3（金額）
  initVals.deductionsOtherDeductions_3Amount =
    job.ecDeductionsOtherDeductions_3Amount;

  //その他控除4（名称）
  //No need to set because the backend handles this

  //その他控除4（金額）
  initVals.deductionsOtherDeductions_4Amount =
    job.ecDeductionsOtherDeductions_4Amount;

  //控除金額の合計
  initVals.deductionsTotalDeductions = job.ecDeductionsTotalDeductions;

  //手取り支給額
  initVals.takeHomeWage = job.ecTakeHomeWage;

  return initVals;
};
