import { filesize } from "filesize";
import { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { DOCUMENT_KINDS } from "../../../constants";
import { Document, Proposal } from "../../../types";
import { Candidate } from "../../../../candidate/types/api";
import Button from "../../../../core/components/Button";
import Column from "../../../../core/components/Column";
import UploadButton from "../../../../core/components/UploadButton";
import Text from "../../../../core/components/Text";
import SendDocumentsModal from "../../../components/SendDocumentsModal";
import ContactInformation from "../../../components/ContactInformation";
import VisaDocAnalysisSegment from "./segments/VisaDocAnalysisSegment";
import EmploymentConditionsStatusSegment from "./segments/EmploymentConditionsStatusSegment";
import EmployeeOfferStatusSegment from "./segments/EmployeeOfferStatusSegment";
import SupportPlansCreationSegment from "./segments/SupportPlansCreationSegment";
import { ReactComponent as OpenInNewIcon } from "../../../../assets/icon-open-in-new.svg";
import { useNavigate } from "react-router-dom";

interface InformationSharingSectionProps {
  proposal?: Proposal;
  documents?: Document[];
  candidate?: Candidate;
  employmentOfferSentDate?: string;
  onDocumentUpload?: (kind: string, file: File) => void;
  onDocumentDelete?: (id: number) => void;
  onDocumentsSend?: () => void;
}

interface UploadedDocumentsProps {
  documents?: Document[];
  kind: string;
  onDocumentDelete?: (id: number) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 730px;
  margin-top: 10px;
  padding: 20px 20px 60px 20px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background: #ffffff;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  color: #444444;
  background: #ffdfd1;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const Blocks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 30px;
  gap: 40px;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  > *:not(:first-child) {
    margin-top: 10px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  color: #444444;
  background: #ffdfd1;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border: 2px solid #ff9e57;
  border-radius: 5px;
  color: #ff9e57;
  background: #ffffff;
  box-sizing: border-box;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  > *:not(:first-child) {
    margin-top: 10px;
  }
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const ActionButton = styled(Button)`
  height: 35px;
  width: 160px;
  padding: 0 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

const DocmentItem = styled(Column)`
  padding: 10px 20px;

  &:nth-child(even) {
    background: #f6f6f6;
  }

  &:nth-child(odd) {
    background: #ffffff;
  }
`;

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: max-content auto 1fr;
  align-items: start;
  column-gap: 20px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
`;

const DocumentLabel = styled.span`
  width: 70px;
  word-break: break-all;
`;

const DocumentDetailContainer = styled(Column)`
  width: 100%;
  overflow: hidden;
  gap: 5px;
`;

const LinkText = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #017698;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const PreviewLink = styled(LinkText)`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 5px;
`;

const DocumentDetail = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  white-space: nowrap;

  > span {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #999999;
  }

  > img {
    width: 16px;
    height: 16px;
    object-fit: cover;
    cursor: pointer;
  }
`;

const DocInfoPanel = styled(Column)`
  gap: 10px;
  overflow: hidden;
`;

const Warning = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  color: #e93232;
  background: #fff0f0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  > img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    object-fit: cover;
  }
`;

const Prompt = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  color: #444444;
  background: #fff3c9;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  > img {
    width: 18px;
    height: 18px;
    margin-right: 14px;
    object-fit: cover;
  }
`;

const UploadedDocuments = ({
  documents,
  kind,
  onDocumentDelete,
}: UploadedDocumentsProps) => {
  const docsOfKind = documents?.filter((doc) => doc.kind === kind);

  if (!docsOfKind || docsOfKind.length === 0) return null;

  return (
    <DocumentDetailContainer>
      {docsOfKind.map((doc) => (
        <DocumentDetail key={`doc-${doc.id}`}>
          <LinkText href={doc.url}>{doc.url.split("/").slice(-1)}</LinkText>
          <span>{String(filesize(doc.size))}</span>
          <img
            src="/images/icon-close.svg"
            onClick={() => onDocumentDelete?.(doc.id)}
            alt="close-icon"
          />
        </DocumentDetail>
      ))}
    </DocumentDetailContainer>
  );
};

const InformationSharingSection: FunctionComponent<
  InformationSharingSectionProps
> = ({
  proposal,
  documents,
  candidate,
  employmentOfferSentDate,
  onDocumentUpload,
  onDocumentDelete,
  onDocumentsSend,
}) => {
  const FILE_SIZE_LIMIT = 10_000_000; // 10MB
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const totalFilesSize = useMemo(() => {
    return documents?.reduce((acc, doc) => acc + doc.size, 0) || 0;
  }, [documents]);
  const isFileSizeExceeded = totalFilesSize > FILE_SIZE_LIMIT;
  const isCandidateEmailNotAvailable = !candidate?.email;
  const getDocumentLabelText = (kind: string) =>
    t(`proposal.document_kinds.${kind}`);
  const handleDocumentUpload = (kind: string, file: File) => {
    onDocumentUpload && onDocumentUpload(kind, file);
  };

  return (
    <Container>
      <Status>{t("proposal.candidate_hired")}</Status>
      <Blocks>
        <Block>
          <Title>
            <Step>1</Step>
            <span>{t("proposal.share_documents")}</span>
          </Title>
          <Content>
            <Action>
              <span>{t("proposal.send_documents_description")}</span>
              <ActionButton
                disabled={
                  !documents?.length ||
                  isCandidateEmailNotAvailable ||
                  isFileSizeExceeded
                }
                onClick={() => setModalOpen(true)}
              >
                {t("proposal.send_documents")}
              </ActionButton>
            </Action>

            {proposal && <SupportPlansCreationSegment proposal={proposal} />}

            {isCandidateEmailNotAvailable && (
              <Warning>
                <img src="/images/icon-error.svg" alt="error-icon" />
                <span>{t("proposal.email_unavailable_warning")}</span>
              </Warning>
            )}
            {isFileSizeExceeded && (
              <Warning>
                <img src="/images/icon-error.svg" alt="error-icon" />
                <span>{t("proposal.size_exceeded_warning")}</span>
              </Warning>
            )}

            <DocumentsContainer>
              {/* 内定証明書 */}
              <DocmentItem>
                <ItemGrid>
                  <DocumentLabel>
                    {getDocumentLabelText(DOCUMENT_KINDS.OFFER_LETTER)}
                  </DocumentLabel>

                  <UploadButton
                    onFileChange={(file) =>
                      handleDocumentUpload(DOCUMENT_KINDS.OFFER_LETTER, file)
                    }
                  />

                  <DocInfoPanel>
                    <UploadedDocuments
                      documents={documents}
                      kind={DOCUMENT_KINDS.OFFER_LETTER}
                      onDocumentDelete={onDocumentDelete}
                    />
                    <PreviewLink
                      onClick={() => {
                        navigate(
                          `/applications/${
                            proposal!.id
                          }/documents/employment_offer`
                        );
                      }}
                    >
                      プレビューを見る
                      <OpenInNewIcon />
                    </PreviewLink>
                  </DocInfoPanel>
                </ItemGrid>
              </DocmentItem>

              {/* 雇用条件書 */}
              <DocmentItem>
                <ItemGrid>
                  <DocumentLabel>
                    {getDocumentLabelText(DOCUMENT_KINDS.EMPLOYMENT_CONDITIONS)}
                  </DocumentLabel>

                  <UploadButton
                    onFileChange={(file) =>
                      handleDocumentUpload(
                        DOCUMENT_KINDS.EMPLOYMENT_CONDITIONS,
                        file
                      )
                    }
                  />

                  <DocInfoPanel>
                    <UploadedDocuments
                        documents={documents}
                        kind={DOCUMENT_KINDS.EMPLOYMENT_CONDITIONS}
                        onDocumentDelete={onDocumentDelete}
                      />
                    { proposal?.employmentCondition &&
                      <PreviewLink
                        onClick={() => {
                          navigate(
                            `/proposals/${
                              proposal!.id
                            }/documents/employment_conditions`
                          );
                        }}
                      >
                        プレビューを見る
                        <OpenInNewIcon />
                      </PreviewLink>
                    }
                  </DocInfoPanel>

                </ItemGrid>
              </DocmentItem>

              {/* その他 */}
              <DocmentItem>
                <ItemGrid>
                  <DocumentLabel>
                    {getDocumentLabelText(DOCUMENT_KINDS.OTHER)}
                  </DocumentLabel>

                  <UploadButton
                    onFileChange={(file) =>
                      handleDocumentUpload(DOCUMENT_KINDS.OTHER, file)
                    }
                  />
                  <UploadedDocuments
                    documents={documents}
                    kind={DOCUMENT_KINDS.OTHER}
                    onDocumentDelete={onDocumentDelete}
                  />
                </ItemGrid>
              </DocmentItem>
            </DocumentsContainer>

            <Prompt>
              <img src="/images/icon-info.svg" alt="info-icon" />
              <span>{t("proposal.share_documents_prompt")}</span>
            </Prompt>

            {proposal && employmentOfferSentDate && (
              <EmployeeOfferStatusSegment
                proposal={proposal}
                employmentOfferSentDate={employmentOfferSentDate}
              />
            )}

            {proposal && (
              <EmploymentConditionsStatusSegment proposal={proposal} />
            )}

            {proposal && candidate && (
              <VisaDocAnalysisSegment
                proposal={proposal}
                candidate={candidate}
                style={{ marginTop: 60 }}
              />
            )}
          </Content>
        </Block>

        <Block>
          <Title>
            <Step>2</Step>
            <span>{t("proposal.get_in_touch")}</span>
          </Title>
          <Content>
            <Text style={{ fontWeight: 500 }}>
              {t("candidate.contact_information")}
            </Text>
            <ContactInformation
              email={candidate?.email}
              lineId={candidate?.lineId}
              facebookProfileLink={candidate?.facebookProfileLink}
            />
          </Content>
        </Block>
      </Blocks>

      <SendDocumentsModal
        open={modalOpen}
        email={candidate?.email}
        documents={documents}
        onClose={() => setModalOpen(false)}
        onConfirm={() => {
          onDocumentsSend?.();
          setModalOpen(false);
        }}
      />
    </Container>
  );
};

export default InformationSharingSection;
