import { useTranslation } from "react-i18next";
import { Section, SectionHeading, SectionHeadingContainer, SectionLabel, SectionLabelCell, SectionRow, SectionValue, SectionValueCell } from "./commonStyle";
import { SectionProps } from "../types/props";
import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { EMPTY_VAL } from "../constants/defaultValue";
import { JobSalaryType, JobState } from "../enums/job";
import { findItemByKind, formatAmount, isNotEmpty } from "../../core/utils";
import { useValueUnit } from "../../core/hooks";
import { Fragment } from "react";
import { useJobTranslation } from "../hook";
import { DAY_OF_MONTH_ITEMS } from "../constants/options";
import { EcPaymentMethod } from "../../core/enums/employmentConditions";
import { MOBILE_DEVICE } from "../../core/constants/styles";
import Text from "../../core/components/Text";
import { LanguageCode } from "../../core/enums/language";
import { EditableTranslationKind } from "../enums/translation";

interface SalarySectionProps extends SectionProps {}

type Benefit = {
  name: string;
  amount: string;
}

type OtherDeduction = {
  name: string;
  amount: string;
}

const LocalSectionLabelCell = styled(SectionLabelCell)`
  flex-direction: column;
  align-items: start;
`;

const GridTable = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, max-content) 1fr;
  gap: 10px;

  @media ${MOBILE_DEVICE} {
    grid-template-columns: 1fr;
    gap: 0;

    & > span:nth-of-type(2n) {
      margin-bottom: 10px;
    }
  }

  div[data-is-displayed-mobile = "true"] & {
    grid-template-columns: 1fr;
    gap: 0;

    & > span:nth-of-type(2n) {
      margin-bottom: 10px;
    }
  }
`;

const OvertimeDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SalarySection: FunctionComponent<SalarySectionProps> = ({
  job
}) => {
  const { addYen, addTimes } = useValueUnit();  
  const { t, i18n } = useTranslation();
  const { t: tJob } = useTranslation('translation', { keyPrefix: 'job' });
  const { t: tCore } = useTranslation('translation', { keyPrefix: 'core' });
  const { findTranslation } = useJobTranslation();
  const isPublished = job?.state === JobState.Published;
  const isInJapanese = i18n.language === LanguageCode.Japanese;
  const [salaryTypeAndAmount, setSalaryTypeAndAmount] = useState<string>(EMPTY_VAL);
  const [baseSalary, setBaseSalary] = useState<string>(EMPTY_VAL);
  const [hourlyWage, setHourlyWage] = useState<string>(EMPTY_VAL);
  const [cutOffDate, setCutOffDate] = useState<string>(EMPTY_VAL);
  const [paymentDate, setPaymentDate] = useState<string>(EMPTY_VAL);
  const [paymentMethod, setPaymentMethod] = useState<string>(EMPTY_VAL);
  const [salaryRaise, setSalaryRaise] = useState<string>(EMPTY_VAL);
  const [salaryRaiseDetails, setSalaryRaiseDetails] = useState<string>(EMPTY_VAL);
  const [bonus, setBonus] = useState<string>(EMPTY_VAL);
  const [bonusTimesPerYear, setBonusTimesPerYear] = useState<string>(EMPTY_VAL);
  const [bonusTimesAmountEtc, setBonusTimesAmountEtc] = useState<string>(EMPTY_VAL);
  const [bonusPaymentPeriods, setBonusPaymentPeriods] = useState<string>(EMPTY_VAL);
  const [severanceAllowance, setSeveranceAllowance] = useState<string>(EMPTY_VAL);
  const [severanceAllowanceDetails, setSeveranceAllowanceDetails] = useState<string>(EMPTY_VAL);
  const [leaveAllowance, setLeaveAllowance] = useState<string>(EMPTY_VAL);
  const [leaveAllowancePercentage, setLeaveAllowancePercentage] = useState<string>(EMPTY_VAL);
  const [fixedOvertimePayLabel, setFixedOvertimePayLabel] = useState<string>("");
  const [fixedOvertimePayAmount, setFixedOvertimePayAmount] = useState<string | null>(null);
  const [fixedOvertimePayHours, setFixedOvertimePayHours] = useState<string | null>(null);
  const [fixedOvertimePayExtraHours, setFixedOvertimePayExtraHours] = useState<string | null>(null);
  const [benefits, setBenefits] = useState<Benefit[]>([]);
  const [approxPaymentPerMonth, setApproxPaymentPerMonth] = useState<string>(EMPTY_VAL);
  const [otherDeductions, setOtherDeductions] = useState<OtherDeduction[]>([]);
  const [totalDeductionAmount, setTotalDeductionAmount] = useState<string>(EMPTY_VAL);
  const [tax, setTax] = useState<string>(EMPTY_VAL);
  const [socialInsurance, setSocialInsurance] = useState<string>(EMPTY_VAL);
  const [employmentInsurance, setEmploymentInsurance] = useState<string>(EMPTY_VAL);
  const [foodExpenses, setFoodExpenses] = useState<string>(EMPTY_VAL);
  const [housingExpenses, setHousingExpenses] = useState<string>(EMPTY_VAL);
  const [takeHomeAmount, setTakeHomeAmount] = useState<string>(EMPTY_VAL);
  const wrapInParentheses = (val: string) => isInJapanese ? `（${val}）` : ` (${val})`;
  
  useEffect(() => {
    if (!job) 
      return;

    switch (job.salaryType) {
      case JobSalaryType.Monthly:
        setSalaryTypeAndAmount(tJob('salary_monthly'));
        break;
      case JobSalaryType.Hourly:
      case JobSalaryType.Daily:
        let textToDisplay = job.salaryType === JobSalaryType.Hourly
          ? tJob('salary_hourly')
          : tJob('salary_daily');
        let base = job.hourlyDailySalary?.toString() ?? null;
        let upper = job.hourlyDailySalaryUpperLimit?.toString() ?? null;

        if (base && upper) {
          upper = addYen(formatAmount(upper));
          textToDisplay += wrapInParentheses(`${base} ~ ${upper}`);
        } else if (base) {
          base = addYen(formatAmount(base));
          textToDisplay += wrapInParentheses(base);
        } else if (upper) {
          upper = addYen(formatAmount(upper));
          textToDisplay += wrapInParentheses(`~ ${upper}`);
        }

        setSalaryTypeAndAmount(textToDisplay);
        break;
      default:
        setSalaryTypeAndAmount(EMPTY_VAL);
    }

    if ((job.grossSalary == null && job.grossSalaryUpperLimit == null))
      setBaseSalary(EMPTY_VAL);
    else {
      const base = `${formatAmount(job.grossSalary)}`;
      const upper = job.grossSalaryUpperLimit == null
        ? ''
        : ` ~ ${formatAmount(job.grossSalaryUpperLimit)}`;
      setBaseSalary(addYen(base + upper));
    }

    if (job.ecBaseWageAmountPerHour)
      setHourlyWage(addYen(formatAmount(job.ecBaseWageAmountPerHour)));
    else
      setHourlyWage(EMPTY_VAL);

    if (job.ecCompensationPayrollCutOffDate_1) {
      const label = DAY_OF_MONTH_ITEMS.find(i => 
        i.value === job.ecCompensationPayrollCutOffDate_1)?.label
      setCutOffDate(label ? t(label) : EMPTY_VAL);
    } else
      setCutOffDate(EMPTY_VAL);

    if (job.ecCompensationPayrollDate_1) {
      const label = DAY_OF_MONTH_ITEMS.find(i => 
        i.value === job.ecCompensationPayrollDate_1)?.label || ''
      setPaymentDate(label ? t(label) : EMPTY_VAL);
    } else
      setPaymentDate(EMPTY_VAL);

    
    switch (job.ecCompensationPaymentMethod) {
      case EcPaymentMethod.BankTransfer:
        setPaymentMethod(tJob('bank_transfer'));
        break;
      case EcPaymentMethod.Cash:
        setPaymentMethod(tJob('currency_payment'));
        break;
      default:
        setPaymentMethod(EMPTY_VAL);
    }

    if (job.salaryRaise === true) 
      setSalaryRaise(tCore('option_available'));
    else if (job.salaryRaise === false)
      setSalaryRaise(tCore('option_not_available'));
    else
      setSalaryRaise(EMPTY_VAL);

    if (job.ecCompensationWageRaiseTimeAmountEtc)
      setSalaryRaiseDetails(
        findTranslation(job.translations, 'ecCompensationWageRaiseTimeAmountEtc') || EMPTY_VAL
      )
    else
      setSalaryRaiseDetails(EMPTY_VAL);        

    if (job.bonus === true)
      setBonus(tCore('option_available'));
    else if (job.bonus === false)
      setBonus(tCore('option_not_available'));
    else
      setBonus(EMPTY_VAL);

    if (job.numberOfBonusPaymentsPerYear == null)
      setBonusTimesPerYear(EMPTY_VAL);
    else
      setBonusTimesPerYear(addTimes(job.numberOfBonusPaymentsPerYear));


    setBonusTimesAmountEtc(
      isPublished?
        findTranslation(job.translations, 'ecCompensationBonusAmountEtc') || EMPTY_VAL
        : job.ecCompensationBonusAmountEtc || EMPTY_VAL
    );

    setBonusPaymentPeriods(
      job?.ecBonusMonths?.map(bm => tCore(`months.${bm.tkey}`)).join(' / ') || EMPTY_VAL
    );

    if (job?.ecCompensationSeverancePayment === true)
      setSeveranceAllowance(tCore('option_available'));
    else if (job?.ecCompensationSeverancePayment === false)
      setSeveranceAllowance(tCore('option_not_available'));
    else
      setSeveranceAllowance(EMPTY_VAL);

    setSeveranceAllowanceDetails(
      isPublished
        ? findTranslation(job.translations, 'ecCompensationSeverancePaymentTimeAmountEtc') || EMPTY_VAL
        : job?.ecCompensationSeverancePaymentTimeAmountEtc || EMPTY_VAL
    );

    if (job?.ecCompensationLeaveAllowance === true)
      setLeaveAllowance(tCore('option_available'));
    else if (job?.ecCompensationLeaveAllowance === false)
      setLeaveAllowance(tCore('option_not_available'));
    else
      setLeaveAllowance(EMPTY_VAL);

    setLeaveAllowancePercentage(
      isPublished
        ? findTranslation(job.translations, 'ecCompensationLeaveAllowanceRate') || EMPTY_VAL
        : job?.ecCompensationLeaveAllowanceRate || EMPTY_VAL
    );

    const fixedOvertimePayNameTrans = findItemByKind(
      job?.editableTranslations, 
      EditableTranslationKind.BenefitsFixedOvertimePayName
    );
    const fallbackFixedOvertimePayName = tJob('fixed_overtime_pay');

    if (fixedOvertimePayNameTrans) {
      const manualValKey = `${i18n.language}Manual` as keyof typeof fixedOvertimePayNameTrans;
      const autoValKey = `${i18n.language}Auto` as keyof typeof fixedOvertimePayNameTrans;
      const manualValue = fixedOvertimePayNameTrans[manualValKey];
      const autoValue = fixedOvertimePayNameTrans[autoValKey];
      setFixedOvertimePayLabel(manualValue || autoValue || fallbackFixedOvertimePayName);
    } else {
      setFixedOvertimePayLabel(tJob(fallbackFixedOvertimePayName));
    }

    if (job?.ecBenefitsFixedOvertimePayAmount != null) {
      setFixedOvertimePayAmount(addYen(formatAmount(job.ecBenefitsFixedOvertimePayAmount)));
    } else {
      setFixedOvertimePayAmount(null);
    }

    if (job?.ecBenefitsFixedOvertimePayPaymentRequirement_1 != null) {
      setFixedOvertimePayHours(
        tJob(
          "fixed_overtime_pay_description", 
          { hours: job.ecBenefitsFixedOvertimePayPaymentRequirement_1 }
        )
      )
    } else {
      setFixedOvertimePayHours(null);
    }

    if (job?.ecBenefitsFixedOvertimePayPaymentRequirement_2 != null) {
      setFixedOvertimePayExtraHours(
        tJob(
          "fixed_overtime_pay_extra",
          { hours: job.ecBenefitsFixedOvertimePayPaymentRequirement_2 }
        )
      )
    } else {
      setFixedOvertimePayExtraHours(null);
    }

    const benefits = 
      isPublished
        ? [
            {
              name: findTranslation(job.translations, 'ecBenefitsBenefit_1Name') ?? '',
              amount: job.ecBenefitsBenefit_1Amount
                ? addYen(formatAmount(job.ecBenefitsBenefit_1Amount))
                : EMPTY_VAL
            },
            {
              name: findTranslation(job.translations, 'ecBenefitsBenefit_2Name') ?? '',
              amount: job.ecBenefitsBenefit_2Amount
                ? addYen(formatAmount(job.ecBenefitsBenefit_2Amount))
                : EMPTY_VAL
            },
            {
              name: findTranslation(job.translations, 'ecBenefitsBenefit_3Name') ?? '',
              amount: job.ecBenefitsBenefit_3Amount
                ? addYen(formatAmount(job.ecBenefitsBenefit_3Amount))
                : EMPTY_VAL
            },
            {
              name: findTranslation(job.translations, 'ecBenefitsBenefit_4Name') ?? '',
              amount: job.ecBenefitsBenefit_4Amount
                ? addYen(formatAmount(job.ecBenefitsBenefit_4Amount))
                : EMPTY_VAL
            }
          ]
        : [
            {
              name: job.ecBenefitsBenefit_1Name ?? '',
              amount: job.ecBenefitsBenefit_1Amount
                ? addYen(formatAmount(job.ecBenefitsBenefit_1Amount))
                : EMPTY_VAL
            },
            {
              name: job.ecBenefitsBenefit_2Name ?? '',
              amount: job.ecBenefitsBenefit_2Amount
                ? addYen(formatAmount(job.ecBenefitsBenefit_2Amount))
                : EMPTY_VAL
            },
            {
              name: job.ecBenefitsBenefit_3Name ?? '',
              amount: job.ecBenefitsBenefit_3Amount
                ? addYen(formatAmount(job.ecBenefitsBenefit_3Amount))
                : EMPTY_VAL
            },
            {
              name: job.ecBenefitsBenefit_4Name ?? '',
              amount: job.ecBenefitsBenefit_4Amount
                ? addYen(formatAmount(job.ecBenefitsBenefit_4Amount))
                : EMPTY_VAL
            }
          ];
    
    setBenefits(benefits.filter(b => {
      return isPublished 
        ? b.name && b.amount !== EMPTY_VAL
        : b.name || b.amount !== EMPTY_VAL;
    }));

    if (job.ecEstimatedPaymentPerMonth != null) 
      setApproxPaymentPerMonth(addYen(formatAmount(job.ecEstimatedPaymentPerMonth)));
    else
      setApproxPaymentPerMonth(EMPTY_VAL);

    if (job.estimatedIncomeTax)
      setTax(addYen(formatAmount(job.estimatedIncomeTax)));
    else
      setTax(EMPTY_VAL);

    if (job.estimatedSocialInsurance)
      setSocialInsurance(addYen(formatAmount(job.estimatedSocialInsurance)));
    else
      setSocialInsurance(EMPTY_VAL);

    if (job.ecDeductionsEmploymentInsurancePremiums)
      setEmploymentInsurance(addYen(formatAmount(job.ecDeductionsEmploymentInsurancePremiums)));
    else
      setEmploymentInsurance(EMPTY_VAL);

    if (job.ecDeductionsFoodExpenses)
      setFoodExpenses(addYen(formatAmount(job.ecDeductionsFoodExpenses)));
    else
      setFoodExpenses(EMPTY_VAL);
    
    if (job.dormitoryFee)
      setHousingExpenses(addYen(formatAmount(job.dormitoryFee)));
    else
      setHousingExpenses(EMPTY_VAL);

    setOtherDeductions([
      {
        name: isPublished
          ? findTranslation(job.translations, 'ecDeductionsOtherDeductions_1Name') ?? '' 
          : job.ecDeductionsOtherDeductions_1Name ?? '',
        amount: job.ecDeductionsOtherDeductions_1Amount
          ? addYen(formatAmount(job.ecDeductionsOtherDeductions_1Amount))
          : EMPTY_VAL
      },
      {
        name: isPublished
          ? findTranslation(job.translations, 'ecDeductionsOtherDeductions_2Name') ?? ''
          : job.ecDeductionsOtherDeductions_2Name ?? '',
        amount: job.ecDeductionsOtherDeductions_2Amount
          ? addYen(formatAmount(job.ecDeductionsOtherDeductions_2Amount))
          : EMPTY_VAL
      },
      {
        name: isPublished
          ? findTranslation(job.translations, 'ecDeductionsOtherDeductions_3Name') ?? ''  
          : job.ecDeductionsOtherDeductions_3Name ?? '',
        amount: job.ecDeductionsOtherDeductions_3Amount
          ? addYen(formatAmount(job.ecDeductionsOtherDeductions_3Amount))
          : EMPTY_VAL
      },
      {
        name: isPublished
          ? findTranslation(job.translations, 'ecDeductionsOtherDeductions_4Name') ?? ''
          : job.ecDeductionsOtherDeductions_4Name ?? '',
        amount: job.ecDeductionsOtherDeductions_4Amount
          ? addYen(formatAmount(job.ecDeductionsOtherDeductions_4Amount))
          : EMPTY_VAL
      }
    ].filter(od => !!od.name));

    if (job.estimatedTotalDeductions)
      setTotalDeductionAmount(addYen(formatAmount(job.estimatedTotalDeductions)));
    else
      setTotalDeductionAmount(EMPTY_VAL);

    if (job.takeHomeSalary)
      setTakeHomeAmount(addYen(formatAmount(job.takeHomeSalary)));
    else
      setTakeHomeAmount(EMPTY_VAL);


  }, [job, i18n.language]);

  return (
    <Section>
      <SectionHeadingContainer>
        <SectionHeading>
          {tJob('salary')}
        </SectionHeading>
      </SectionHeadingContainer>

       {/* 支給形態 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('salary_type')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell> 
          <SectionValue>
            {salaryTypeAndAmount}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 基本給 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('gross_salary')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{baseSalary}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 1時間あたりの金額 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('amount_per_hour')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{hourlyWage}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 締切日 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('closing_date')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{cutOffDate}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 支払日 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('payment_date')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{paymentDate}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 支払い方法 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('payment_method')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{paymentMethod}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 昇給 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('salary_raise')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{salaryRaise}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 昇給の時期・金額等 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('salary_raise_timing_amount_etc')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{salaryRaiseDetails}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 賞与 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('bonus')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{bonus}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 賞与の支給回数（年間） */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('number_of_bonus_payments_per_year')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {bonusTimesPerYear}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 賞与の支給時期 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('bonus_payment_time')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{bonusPaymentPeriods}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 賞与の金額など */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('bonus_amount_etc')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{bonusTimesAmountEtc}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 退職金 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('severance_payment')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{severanceAllowance}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 退職金の条件・金額等 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('severance_payment_condition_amount_etc')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{severanceAllowanceDetails}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 休業手当 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('leave_allowance')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{leaveAllowance}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 休業手当の支給額（平均賃金の割合） */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('leave_allowance_amount')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{leaveAllowancePercentage}</SectionValue>
        </SectionValueCell>
      </SectionRow>

     {/* 固定残業代 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {fixedOvertimePayLabel}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <OvertimeDetailContainer>
            {[fixedOvertimePayAmount, 
              fixedOvertimePayHours, 
              fixedOvertimePayExtraHours].some(isNotEmpty)
              ? <>
                  {fixedOvertimePayAmount &&
                    <SectionValue>{fixedOvertimePayAmount}</SectionValue>
                  }
                  {fixedOvertimePayHours &&
                    <SectionValue>{fixedOvertimePayHours}</SectionValue>
                  }
                  {fixedOvertimePayExtraHours &&
                    <SectionValue>{fixedOvertimePayExtraHours}</SectionValue>
                  }
                </>
              : EMPTY_VAL
            }
          </OvertimeDetailContainer>
        </SectionValueCell>
      </SectionRow>

      {/* 手当 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('allowance')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          { benefits.length > 0 
            ? <GridTable>
                { benefits.map((be, i) => (
                  <Fragment key={`benefit-${i}`}>
                    <SectionValue>{be.name}</SectionValue>
                    <SectionValue>{be.amount}</SectionValue>
                  </Fragment>
                  ))
                }
                <SectionValue>
                  <strong>{tJob('approx_payment')}</strong>
                </SectionValue>
                <SectionValue>
                  <strong>{approxPaymentPerMonth}</strong>
                </SectionValue>
              </GridTable>

            : <SectionValue>{EMPTY_VAL}</SectionValue>
          } 
        </SectionValueCell>
      </SectionRow>

      {/* 控除 */}
      <SectionRow>
        <LocalSectionLabelCell>
          <SectionLabel>
            {tJob("deductions")}
          </SectionLabel>
          <Text>
            {tJob('this_is_estimated_amount')}
          </Text>
        </LocalSectionLabelCell>
        <SectionValueCell>
          <GridTable>
            <SectionValue>{tJob('withholding_tax')}</SectionValue>
            <SectionValue>{tax}</SectionValue>

            <SectionValue>{tJob('social_insurance_premiums')}</SectionValue>
            <SectionValue>{socialInsurance}</SectionValue>

            <SectionValue>{tJob('employment_insurance_premium')}</SectionValue>
            <SectionValue>{employmentInsurance}</SectionValue>

            <SectionValue>{tJob('food_expenses')}</SectionValue>
            <SectionValue>{foodExpenses}</SectionValue>

            <SectionValue>{tJob('residence_fee')}</SectionValue>
            <SectionValue>{housingExpenses}</SectionValue>
          </GridTable>
        </SectionValueCell>
      </SectionRow>

      {/* その他の控除 */}
      <SectionRow>
        <LocalSectionLabelCell>
          <SectionLabel>
            {tJob('other_deductions')}
          </SectionLabel>
          <Text>
            {tJob('this_is_estimated_amount')}
          </Text>
        </LocalSectionLabelCell>
        <SectionValueCell>
          { otherDeductions.length > 0 
            ? <GridTable>
                { otherDeductions.map((od, i) => (
                    <Fragment key={`deduction-${i}`}>
                      <SectionValue>{od.name}</SectionValue>
                      <SectionValue>{od.amount}</SectionValue>
                    </Fragment>
                  ))
                }
                <SectionValue>
                  <strong>{tJob('total_deduction_amount')}</strong>
                </SectionValue>
                <SectionValue>
                  <strong>
                    {totalDeductionAmount}
                  </strong>
                </SectionValue>
              </GridTable>

            : <SectionValue>{EMPTY_VAL}</SectionValue>
          }
        </SectionValueCell>
      </SectionRow>

      {/* 手取り支給額 */}
      <SectionRow>
        <LocalSectionLabelCell>
          <SectionLabel>
            {tJob('take_home_pay_amount')}
          </SectionLabel>
          <Text>
            {tJob('this_is_estimated_amount')}
          </Text>
        </LocalSectionLabelCell>
        <SectionValueCell>
          <SectionValue>{takeHomeAmount}</SectionValue>
        </SectionValueCell>
      </SectionRow>

    </Section>
  );
};

export default SalarySection;