import { useTranslation } from "react-i18next";
import { Section, SectionHeading, SectionHeadingContainer, SectionLabel, SectionLabelCell, SectionRow, SectionValue, SectionValueCell, SectionValuesContainer } from "./commonStyle";
import { SectionProps } from "../types/props";
import { FunctionComponent, useEffect, useState } from "react";
import { EMPTY_VAL } from "../constants/defaultValue";
import { useValueUnit } from "../../core/hooks";
import { JobState, JobWorkStyle } from "../enums/job";
import { EcVariableWorkingHoursUnit } from "../../core/enums/employmentConditions";
import { normalizeDecimalNumber, pad0 } from "../../core/utils";
import { JobTranslation } from "../types/api";
import { LanguageCode } from "../../core/enums/language";
import { get } from "http";

interface WorkHoursSectionProps extends SectionProps {}

const WorkHoursSection: FunctionComponent<WorkHoursSectionProps> = ({
  job
}) => {
  const { addDay, addHourAndMinute, addHour, addMinute } = useValueUnit();
  const { i18n } = useTranslation();
  const { t: tJob } = useTranslation('translation', { keyPrefix: 'job' });
  const { t: tCore }= useTranslation('translation', { keyPrefix: 'core' });
  const [basicWorkHours, setBasicWorkHours] = useState<string>(EMPTY_VAL);
  const [workStyle, setWorkStyle] = useState<string>(EMPTY_VAL);
  const [fixedWorkHours, setFixedWorkHours] = useState<string>();
  const [shift1Detail, setShift1Detail] = useState<string>();  
  const [shift2Detail, setShift2Detail] = useState<string>();
  const [shift3Detail, setShift3Detail] = useState<string>();
  const [variableWorkTime, setVariableWorkTime] = useState<string>(EMPTY_VAL);
  const [monthlyWorkDays, setMonthlyWorkDays] = useState<string>(EMPTY_VAL);
  const [monthlyOvertime, setMonthlyOvertime] = useState<string>(EMPTY_VAL);
  const [breakTime, setBreakTime] = useState<string>(EMPTY_VAL);
  const [scheduledNumOfWorkingHoursWeek, setScheduledNumOfWorkingHoursWeek] = useState<string>(EMPTY_VAL);
  const [scheduledNumOfWorkingHoursMonthly, setScheduledNumOfWorkingHoursMonthly] = useState<string>(EMPTY_VAL);
  const [scheduledNumOfWorkingHoursAnnual, setScheduledNumOfWorkingHoursAnnual] = useState<string>(EMPTY_VAL);
  const [scheduledNumOfWorkingDaysWeek, setScheduledNumOfWorkingDaysWeek] = useState<string>(EMPTY_VAL);
  const [scheduledNumOfWorkingDaysMonthly, setScheduledNumOfWorkingDaysMonthly] = useState<string>(EMPTY_VAL);
  const [scheduledNumOfWorkingDaysAnnual, setScheduledNumOfWorkingDaysAnnual] = useState<string>(EMPTY_VAL);
  const [overtimeWorkingHours, setOvertimeWorkingHours] = useState<string>(EMPTY_VAL);
  const isPublished = job?.state === JobState.Published;
  const getTranslation = (key: keyof Omit<
    JobTranslation,
    "id" | "language" | "createdAt" | "updatedAt" | "jobPostId"
  >) => 
    job?.translations?.find(t => t.language.code === i18n.language)?.[key] || null;
  const formatWorkHours = (
    startHour: number | null | undefined, 
    startMinute: number | null | undefined,
    endHour: number | null | undefined,
    endMinute: number | null | undefined
  ) => {
    
    if (isPublished) {
      let startTime = null;
      let endTime = null;

      if (startHour != null && startMinute != null)
        startTime = `${pad0(startHour)}:${pad0(startMinute)}`;
  
      if (endHour != null && endMinute != null)
        endTime = `${pad0(endHour)}:${pad0(endMinute)}`;
  
      return (startTime || endTime) ? `${startTime ?? ''} ~ ${endTime ?? ''}` : null;
    }

    //In the case of draft
    return `${pad0(startHour)}:${pad0(startMinute)} ~ ${pad0(endHour)}:${pad0(endMinute)}`;
  };
  const getShiftTimeDetail = (shiftTimeStr: string | null, applicationDate: string | null | undefined) => {
    const isJapanese = i18n.language === LanguageCode.Japanese;
    let applicationDateStr = "";

    if (applicationDate) {
      applicationDateStr = isJapanese 
        ? `（${applicationDate}）`
        : `(${applicationDate})`;
    }

    return shiftTimeStr ? `${shiftTimeStr} ${applicationDateStr}`.trim() : "";
  };

  useEffect(() => {
    if (!job) 
      return;

    setBasicWorkHours(addHourAndMinute(
      job.basicWorkHoursPerDay, job.basicWorkMinutesPerDay
    ) || EMPTY_VAL);

    switch (job.workStyle) {
      case JobWorkStyle.FixedHours:
        setWorkStyle(tJob('fixed_hours'));
        setFixedWorkHours(formatWorkHours(
          job.ecWorkHoursStartTimeHour,
          job.ecWorkHoursStartTimeMinute,
          job.ecWorkHoursEndTimeHour,
          job.ecWorkHoursEndTimeMinute
        ) ?? '');
        break;
      case JobWorkStyle.ShiftSystem:
        setWorkStyle(tJob('shift_system'));

        const shift1TimeStr = formatWorkHours(
          job.ecWorkHoursShiftSystem_1StartTimeHour,
          job.ecWorkHoursShiftSystem_1StartTimeMinute,
          job.ecWorkHoursShiftSystem_1EndTimeHour,
          job.ecWorkHoursShiftSystem_1EndTimeMinute
        );
        const shift1AppDate = isPublished
          ? getTranslation('ecWorkHoursShiftSystem_1ApplicationDate')
          : job.ecWorkHoursShiftSystem_1ApplicationDate;

        const shift2TimeStr = formatWorkHours(
          job.ecWorkHoursShiftSystem_2StartTimeHour,
          job.ecWorkHoursShiftSystem_2StartTimeMinute,
          job.ecWorkHoursShiftSystem_2EndTimeHour,
          job.ecWorkHoursShiftSystem_2EndTimeMinute
        );
        const shift2AppDate = isPublished
          ? getTranslation('ecWorkHoursShiftSystem_2ApplicationDate')
          : job.ecWorkHoursShiftSystem_2ApplicationDate;

        const shift3TimeStr = formatWorkHours(
          job.ecWorkHoursShiftSystem_3StartTimeHour,
          job.ecWorkHoursShiftSystem_3StartTimeMinute,
          job.ecWorkHoursShiftSystem_3EndTimeHour,
          job.ecWorkHoursShiftSystem_3EndTimeMinute
        );
        const shift3AppDate = isPublished
          ? getTranslation('ecWorkHoursShiftSystem_3ApplicationDate')
          : job.ecWorkHoursShiftSystem_3ApplicationDate;
        
        setShift1Detail(getShiftTimeDetail(shift1TimeStr, shift1AppDate));
        setShift2Detail(getShiftTimeDetail(shift2TimeStr, shift2AppDate));
        setShift3Detail(getShiftTimeDetail(shift3TimeStr, shift3AppDate));        

        break;
      default:
        setWorkStyle(EMPTY_VAL);
    }

    if (job.ecWorkHoursVariableWorkingHoursSystem === true) {
      let unit = null;
      switch (job.ecWorkHoursVariableWorkingHoursSystemUnit) {
        case EcVariableWorkingHoursUnit.Year:
          unit = tJob('variable_working_hours_unit.1year');
          break;
        case EcVariableWorkingHoursUnit.Month:
          unit = tJob('variable_working_hours_unit.1month');
          break;
        case EcVariableWorkingHoursUnit.Week:
          unit = tJob('variable_working_hours_unit.1week');
          break;
      }
      setVariableWorkTime(
        `${tCore('option_available')}${unit ? ` (${unit})` : ''}` 
      )
    } else if (job.ecWorkHoursVariableWorkingHoursSystem === false) {
      setVariableWorkTime(tCore('option_not_available'));
    } else {
      setVariableWorkTime(EMPTY_VAL);
    }

    setMonthlyWorkDays(addDay(job.averageMonthlyWorkDays) || EMPTY_VAL);
    setMonthlyOvertime(addHour(job.averageMonthlyOvertimeHours) || EMPTY_VAL);
    setBreakTime(addMinute(job.ecWorkHoursBreakTime) || EMPTY_VAL);

    setScheduledNumOfWorkingHoursWeek(addHour(job.ecWorkHoursWeeklyNormalWorkingHour) || EMPTY_VAL);
    setScheduledNumOfWorkingHoursMonthly(addHour(job.ecWorkHoursMonthlyNormalWorkingHour) || EMPTY_VAL);
    setScheduledNumOfWorkingHoursAnnual(addHour(job.ecWorkHoursAnnualNormalWorkingHour) || EMPTY_VAL);

    //the franction part is removed if it is 0 (e.g. 120.0 => 120)
    const normalizedWeeklyWorkingDay1 = normalizeDecimalNumber(job.ecWorkHoursWeeklyNormalWorkingDay_1);
    const normalizedWeeklyWorkingDay2 = normalizeDecimalNumber(job.ecWorkHoursWeeklyNormalWorkingDay_2);
    const normalizedMonthlyWorkingDay = normalizeDecimalNumber(job.ecWorkHoursMonthlyNormalWorkingDay);
    const normalizedAnnualWorkingDay = normalizeDecimalNumber(job.ecWorkHoursAnnualNormalWorkingDay);

    setScheduledNumOfWorkingDaysWeek(
        (normalizedWeeklyWorkingDay1 || normalizedWeeklyWorkingDay2)
            ? addDay(normalizedWeeklyWorkingDay1, normalizedWeeklyWorkingDay2) : EMPTY_VAL
    );
    setScheduledNumOfWorkingDaysMonthly(
      normalizedMonthlyWorkingDay ? addDay(normalizedMonthlyWorkingDay) : EMPTY_VAL
    );
    setScheduledNumOfWorkingDaysAnnual(
      normalizedAnnualWorkingDay ? addDay(normalizedAnnualWorkingDay) : EMPTY_VAL
    );

    if (job.ecWorkHoursOvertimeWork === true)
      setOvertimeWorkingHours(tJob('applicable'));
    else if (job.ecWorkHoursOvertimeWork === false)
      setOvertimeWorkingHours(tJob('not_applicable'));
    else
        setOvertimeWorkingHours(EMPTY_VAL);

  }, [job, i18n.language]);  

  return (
    <Section>
      <SectionHeadingContainer>
        <SectionHeading>
          {tJob('job_working_hours')}
        </SectionHeading>
      </SectionHeadingContainer>

       {/* 一日の勤務時間 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('basic_work_hours')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValuesContainer>
            <SectionValue>
              {basicWorkHours}
            </SectionValue>
          </SectionValuesContainer>
        </SectionValueCell>
      </SectionRow>

      {/* 勤務形態 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('work_style')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {workStyle}
            {fixedWorkHours && (
              <>
                <br />
                {fixedWorkHours}
              </>
            )}
            {shift1Detail && (
              <>
                <br />
                {shift1Detail}
              </>
            )}
            {shift2Detail && (
              <>
                <br />
                {shift2Detail}
              </>
            )}
            {shift3Detail && (
              <>
                <br />
                {shift3Detail}
              </>
            )}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 変形労働時間制 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('variable_working_hours_system')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {variableWorkTime}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 労働日数（月平均）*/}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('monthly_work_days')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {monthlyWorkDays}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 残業時間（月平均）*/}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('monthly_overtime')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {monthlyOvertime}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 休憩時間 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('break')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {breakTime}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 所定労働時間数（週）*/}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('scheduled_num_of_working_hours_week')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {scheduledNumOfWorkingHoursWeek}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 所定労働時間数（月間）*/}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('scheduled_num_of_working_hours_monthly')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {scheduledNumOfWorkingHoursMonthly}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 所定労働時間数（年間）*/}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('scheduled_num_of_working_hours_annual')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {scheduledNumOfWorkingHoursAnnual}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 所定労働日数（週）*/}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('scheduled_num_of_working_days_week')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {scheduledNumOfWorkingDaysWeek}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 所定労働日数（月間）*/}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('scheduled_num_of_working_days_monthly')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {scheduledNumOfWorkingDaysMonthly}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 所定労働日数（年間）*/}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('scheduled_num_of_working_days_annual')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {scheduledNumOfWorkingDaysAnnual}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 所定外労働時間 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('overtime_working_hours')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {overtimeWorkingHours}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

    </Section>
  );
};

export default WorkHoursSection;