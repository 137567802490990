import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

type RowProps = HTMLAttributes<HTMLDivElement> & {
    center?: boolean;
    children?: ReactNode;
    fullWidth?: boolean;
    gap?: number;
    spaceBetween?: boolean;
};

export const Container = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  align-items: ${(props: RowProps) => props.center ? 'center' : 'flex-start'};
  justify-content: ${(props: RowProps) => props.spaceBetween ? 'space-between' : 'flex-start'};
  width: ${(props: RowProps) => props.fullWidth ? '100%' : 'auto'};
  gap: ${(props: RowProps) => props.gap ? `${props.gap}px` : '0'};
`;

const Row: FunctionComponent<RowProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default Row;