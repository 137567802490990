import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

type ColumnProps = HTMLAttributes<HTMLDivElement> & {
    center?: boolean;
    children?: ReactNode;
    fullWidth?: boolean;
    gap?: number;
};

export const Container = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props: ColumnProps) => props.center ? 'center' : 'flex-start'};
  width: ${(props: ColumnProps) => props.fullWidth ? '100%' : 'auto'};
  gap: ${(props: ColumnProps) => props.gap ? `${props.gap}px` : '0'};
`;

const Column: FunctionComponent<ColumnProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default Column;