import styled from "styled-components";
import Text from "../../../../core/components/Text";
import { TranslationItem } from "../type";
import { Column10 } from "../commonStyle";

interface MultilingualInputFieldProps extends TranslationItem {}


const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 230px) 1fr;
  border-bottom: 1px solid #d5d5d5;

  &:last-child {
    border-bottom: none;
  }
`;

const LabelField = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 30px;
  justify-content: center;
  gap: 6px;
`;

const LabelText = styled(Text)`
  font-weight: 700;
`;

const ContentField = styled.div`
  display: flex;
  padding: 10px 30px 10px 20px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`;

const InputButton = styled.button`
  min-width: 114px;
  border-radius: 5px;
  border: 1px solid #acacac;
  background: #fff;
  min-height: 30px;
  padding: 0 15px;
  font-weight: 700;
  color: #444;
  cursor: pointer;
`;

const TransTextsContainer = styled.div``;

const TransText = styled(Text)`
  color: #999;
`;


const MultilingualInputField: React.FunctionComponent<
  MultilingualInputFieldProps
> = ({
  label,
  note,
  translation,
  showOrgInfoReflectButton,
  onClickInputButton,
  onClickOrgInfoReflectButton
}) => {
  return (
    <ItemContainer>
      <LabelField>
        <LabelText>{label}</LabelText>
        {note && <Text>{note}</Text>}
      </LabelField>
      <ContentField>
        <Column10>
          <InputButton onClick={onClickInputButton}>
            入力
          </InputButton>
          {showOrgInfoReflectButton && (
            <InputButton onClick={onClickOrgInfoReflectButton}>
              組織情報を反映
            </InputButton>
          )}
        </Column10>
        <TransTextsContainer>
          <TransText>
            {translation?.jaManual || "説明テキストを入力"}
          </TransText>
          <TransText>
            {translation?.enManual ||
              translation?.enAuto ||
              "Enter descriptive text"}
          </TransText>
          <TransText>
            {translation?.idManual ||
              translation?.idAuto ||
              "Masukkan teks deskriptif"}
          </TransText>
          <TransText>
            {translation?.neManual ||
              translation?.neAuto ||
              "वर्णनात्मक पाठ प्रविष्ट गर्नुहोस्"}
          </TransText>
          <TransText>
            {translation?.viManual ||
              translation?.viAuto ||
              "Nhập văn bản mô tả"}
          </TransText>
          <TransText>
            {translation?.myManual ||
              translation?.myAuto ||
              "ဖော်ပြချက်စာသားကို ရိုက်ထည့်ပါ။"}
          </TransText>
        </TransTextsContainer>
      </ContentField>
    </ItemContainer>
  );
};

export default MultilingualInputField;
