import { FunctionComponent, useMemo } from "react";
import {
  Column5,
  FieldCell,
  GridRow,
  GridRowsContainer,
  HeadingContainer,
  LabelCell,
  LocalMultiTextInputMiddle,
  LocalMultiTextInputSmall,
  LocalPostalCodeInput,
  LocalSelectExtend,
  LocalTextInput,
  LocalTextInputGrow,
  ContentAndButtonContainer,
  OrgInfoReflectButton,
  PostalCodeAndNoteWrapper,
  SectionHeadingLarge,
  TransValFieldGroup,
  TransValFieldGroupStartAligned,
  WarningNote,
} from "../commonStyle";
import Label from "../../../../core/components/Label";
import Text from "../../../../core/components/Text";
import { JobFormSectionProps } from "../type";
import { useTranslation } from "react-i18next";
import { isEmpty, isFirstEmptySecondNot, isNotEmpty, sanitizePostalCode } from "../../../../core/utils";
import { useReducerContext } from "../../../../core/contexts/ReducerContext";
import { usePrefectures } from "../../../../core/hooks";
import { JobExtended } from "../../../types/api";
import { Company } from "../../../../core/types/api";
import { getAddressInformation } from "../../../../core/apis/address";
import ACTIONS from "../../../../core/constants/actions";

interface CompanyInfoSectionProps extends JobFormSectionProps {}

const CompanyInfoSection: FunctionComponent<CompanyInfoSectionProps> = ({
  job,
  onChange,
  company
}) => {
  const { state, dispatch } = useReducerContext();
  const { prefectureWithJaValOptions, findPrefEnValByJaVal } = usePrefectures();
  const { t: tCore } = useTranslation("translation", { keyPrefix: "core" });
  const keyMapping: Partial<Record<keyof JobExtended, keyof Company>> = {
    ecEmployerEnOrganizationName: "enName",
    ecEmployerPostalCode: "postalCode",
    ecEmployerJaCityWard: "jaCityWard",
    ecEmployerEnCityWard: "enCityWard",
    ecEmployerJaTown: "jaTown",
    ecEmployerEnTown: "enTown",
    ecEmployerJaAddressNumber: "jaAddressNumber",
    ecEmployerEnAddressNumber: "enAddressNumber",
    ecEmployerJaBuilding: "jaBuildingName",
    ecEmployerEnBuilding: "enBuildingName",
    ecEmployerPhoneNumber: "phoneNumber",
    ecEmployerJaRepresentativeName: "jaRepresentativeName",
    ecEmployerEnRepresentativeName: "enRepresentativeName",
  };

  const showReflectButton = useMemo(() => 
    Object.entries(keyMapping).some(([jobKey, companyKey]) => {
    const jobVal = job?.[jobKey as keyof JobExtended];
    const companyVal = company?.[companyKey];

    return isFirstEmptySecondNot(jobVal, companyVal);
  }), [job, company]);

  const onClickReflectButton = async () => {
    const newVals: Partial<JobExtended> = {};

    if (isEmpty(job?.ecEmployerEnOrganizationName))
      newVals.ecEmployerEnOrganizationName = company?.enName;

    if (isEmpty(job?.ecEmployerPostalCode)) {
      newVals.ecEmployerPostalCode = sanitizePostalCode(company?.postalCode) ?? "";

      
      /* Don't assign the prefecture values of the company to the job form
      because they can be anything (i.e. free text) meanwhile the job form
      uses a fixed list of prefectures fetched through an API */
      try {
        dispatch({ type: ACTIONS.START_LOADING });
        const companyAddrInfo = await getAddressInformation(newVals.ecEmployerPostalCode!);
        newVals.ecEmployerJaPrefecture = companyAddrInfo?.jaPrefecture;
        newVals.ecEmployerEnPrefecture = companyAddrInfo?.enPrefecture;
      } catch {
        //Probably, no need to handle error
      } finally {
        dispatch({ type: ACTIONS.STOP_LOADING });
      }
    }

    if (isEmpty(job?.ecEmployerJaCityWard))
      newVals.ecEmployerJaCityWard = company?.jaCityWard;

    if (isEmpty(job?.ecEmployerEnCityWard))
      newVals.ecEmployerEnCityWard = company?.enCityWard;

    if (isEmpty(job?.ecEmployerJaTown))
      newVals.ecEmployerJaTown = company?.jaTown;

    if (isEmpty(job?.ecEmployerEnTown))
      newVals.ecEmployerEnTown = company?.enTown;

    if (isEmpty(job?.ecEmployerJaAddressNumber))
      newVals.ecEmployerJaAddressNumber = company?.jaAddressNumber;

    if (isEmpty(job?.ecEmployerEnAddressNumber))
      newVals.ecEmployerEnAddressNumber = company?.enAddressNumber;

    if (isEmpty(job?.ecEmployerJaBuilding))
      newVals.ecEmployerJaBuilding = company?.jaBuildingName;

    if (isEmpty(job?.ecEmployerEnBuilding))
      newVals.ecEmployerEnBuilding = company?.enBuildingName;

    if (isEmpty(job?.ecEmployerPhoneNumber))
      newVals.ecEmployerPhoneNumber = company?.phoneNumber;

    if (isEmpty(job?.ecEmployerJaRepresentativeName))
      newVals.ecEmployerJaRepresentativeName = company?.jaRepresentativeName;

    if (isEmpty(job?.ecEmployerEnRepresentativeName))
      newVals.ecEmployerEnRepresentativeName = company?.enRepresentativeName;

    if (isEmpty(job?.ecEmployerJaRepresentativeTitle))
      newVals.ecEmployerJaRepresentativeTitle = company?.jaRepresentativeTitle;

    if (isEmpty(job?.ecEmployerEnRepresentativeTitle))
      newVals.ecEmployerEnRepresentativeTitle = company?.enRepresentativeTitle;

    if (isNotEmpty(newVals))
      onChange?.(newVals);

  }


  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>企業情報</SectionHeadingLarge>
        <ContentAndButtonContainer>
          <WarningNote>
            ※この情報は、求職者へのプレビュー表示や雇用条件書作成時に使用します。
          </WarningNote>
          { showReflectButton &&
            <OrgInfoReflectButton
              onClick={onClickReflectButton}
            >
              組織情報を一括反映
            </OrgInfoReflectButton>
          }
        </ContentAndButtonContainer>
      </HeadingContainer>

      <GridRowsContainer>
        {/* 会社/機関名） */}
        <GridRow>
          <LabelCell>
            <Label required>会社/機関名</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroupStartAligned>
              <Text>日</Text>
              <LocalMultiTextInputSmall
                disabled
                value={job?.ecEmployerJaOrganizationName ?? state.company?.jaName ?? ""}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalMultiTextInputSmall
                placeholder="Nippon Kohgyo Co, Ltd."
                value={job?.ecEmployerEnOrganizationName ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerEnOrganizationName: val })}
                invalid={isEmpty(job?.ecEmployerEnOrganizationName)}
              />
            </TransValFieldGroupStartAligned>
          </FieldCell>
        </GridRow>

        {/* 郵便番号 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("postal_code")}</Label>
          </LabelCell>
          <FieldCell>
            <PostalCodeAndNoteWrapper>
              <Column5>
                <Text>
                  郵便番号を入力すると、該当する都道府県、市区、町村が自動入力されます。
                </Text>
                <LocalPostalCodeInput
                  suppressErrorMessage
                  clearAddrInfoWhenIncomplete
                  value={job?.ecEmployerPostalCode ?? ""}
                  onTextChange={(val) => {
                    onChange?.({ ecEmployerPostalCode: val });
                  }}
                  onPrefectureChange={(val) => {
                    onChange?.({
                      ecEmployerJaPrefecture: val.jaPrefecture,
                      ecEmployerEnPrefecture: val.enPrefecture,
                    });
                  }}
                  onCityChange={(val) => {
                    onChange?.({
                      ecEmployerJaCityWard: val.jaCity,
                      ecEmployerEnCityWard: val.enCity,
                    });
                  }}
                  onTownChange={(val) => {
                    onChange?.({
                      ecEmployerJaTown: val.jaTown,
                      ecEmployerEnTown: val.enTown,
                    });
                  }}
                  error={isEmpty(job?.ecEmployerPostalCode)}
                />
              </Column5>
            </PostalCodeAndNoteWrapper>
          </FieldCell>
        </GridRow>

        {/* 都道府県 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("prefecture")}</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalSelectExtend
                placeholder={tCore("select")}
                options={prefectureWithJaValOptions}
                value={job?.ecEmployerJaPrefecture}
                onValueChange={(val) => {
                  onChange?.({ 
                    ecEmployerJaPrefecture: val,
                    ecEmployerEnPrefecture: findPrefEnValByJaVal(val)
                  });
                }}
                error={isEmpty(job?.ecEmployerJaPrefecture)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                disabled
                value={job?.ecEmployerEnPrefecture ?? ""}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 市区 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("city")}</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="港区"
                value={job?.ecEmployerJaCityWard ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerJaCityWard: val })}
                error={isEmpty(job?.ecEmployerJaCityWard)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="Minato-ku"
                value={job?.ecEmployerEnCityWard ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerEnCityWard: val })}
                error={isEmpty(job?.ecEmployerEnCityWard)}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 町村 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("town_name")}</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="海岸"
                value={job?.ecEmployerJaTown ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerJaTown: val })}
                error={isEmpty(job?.ecEmployerJaTown)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="Kaigan"
                value={job?.ecEmployerEnTown ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerEnTown: val })}
                error={isEmpty(job?.ecEmployerEnTown)}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 番地 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("locality")}</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="１丁目９−１８"
                value={job?.ecEmployerJaAddressNumber ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerJaAddressNumber: val })}
                error={isEmpty(job?.ecEmployerJaAddressNumber)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="1-9-18"
                value={job?.ecEmployerEnAddressNumber ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerEnAddressNumber: val })}
                error={isEmpty(job?.ecEmployerEnAddressNumber)}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 建物名等 */}
        <GridRow>
          <LabelCell>
            <Label>{tCore("building_etc")}</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroupStartAligned>
              <Text>日</Text>
              <LocalMultiTextInputMiddle
                placeholder="國際浜松町ビル 2階"
                value={job?.ecEmployerJaBuilding ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerJaBuilding: val })}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalMultiTextInputMiddle
                placeholder="International Hamamatsucho Building 2F"
                value={job?.ecEmployerEnBuilding ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerEnBuilding: val })}
              />
            </TransValFieldGroupStartAligned>
          </FieldCell>
        </GridRow>

        {/* 電話番号 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("phone_number")}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInput
              placeholder="03-1234-5678"
              value={job?.ecEmployerPhoneNumber ?? ""}
              onTextChange={(val) =>
                onChange?.({ ecEmployerPhoneNumber: val })
              }
              error={isEmpty(job?.ecEmployerPhoneNumber)}
            />
          </FieldCell>
        </GridRow>

        {/* 代表者名 */}
        <GridRow>
          <LabelCell>
            <Label>代表者名</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="特定太郎"
                value={job?.ecEmployerJaRepresentativeName ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerJaRepresentativeName: val })}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="Taro Tokutei"
                value={job?.ecEmployerEnRepresentativeName ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerEnRepresentativeName: val })}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 代表者役職 */}
        <GridRow>
          <LabelCell>
            <Label>代表者役職</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="代表取締役"
                value={job?.ecEmployerJaRepresentativeTitle ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerJaRepresentativeTitle: val })}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="CEO"
                value={job?.ecEmployerEnRepresentativeTitle ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerEnRepresentativeTitle: val })}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default CompanyInfoSection;
