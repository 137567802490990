import { FunctionComponent, InputHTMLAttributes, useEffect, useState } from "react";
import TextInput from "./TextInput";
import styled from "styled-components";
import Row from "./Row";
import { isEmpty } from "../utils";

//Spec:
//https://www.figma.com/file/UwZXypeCGS6TGrveckqOOU/tokuty?type=design&node-id=4955-109056&mode=design&t=113laKbAFV2BEylV-4
interface TimeFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  hours?: number | null;
  minutes?: number | null;
  placeholderForHours?: string;
  placeholderForMinutes?: string;
  onHoursChange?: (value: number | null) => void;
  onMinutesChange?: (value: number | null) => void;
  onHoursBlur?: () => void;
  onMinutesBlur?: () => void;
  errorMinutes?: boolean;
  errorHours?: boolean;
} 

const LocalTextInput = styled(TextInput)`
  text-align: left;
  width: 36px;
  padding-left: 10px;
`;

const Container = styled(Row)`
  align-items: center;
  gap: 5px;
`;

const TimeField: FunctionComponent<TimeFieldProps> = ({
  hours,
  minutes,
  placeholderForHours,
  placeholderForMinutes,
  onHoursChange,
  onMinutesChange,
  onHoursBlur,
  onMinutesBlur,
  errorHours,
  errorMinutes,
  ...props
}) => {
  const hourRegx = /^(0|[0-1]?[0-9]|2[0-3])$/;
  const minuteRegx = /^([0-5]?[0-9])$/;
  const [displayHours, setDisplayHours] = useState<string>("");
  const [displayMinutes, setDisplayMinutes] = useState<string>("");
  const [isHoursUpdated, setIsHoursUpdated] = useState(false);
  const [isMinutesUpdated, setIsMinutesUpdated] = useState(false);
  const pad0 = (val?: number | null) => val != null ? val.toString().padStart(2, '0') : "";

  const handleHoursChange = (newValue: string) => {
    if (hourRegx.test(newValue) || isEmpty(newValue)) {
      setDisplayHours(newValue);
      onHoursChange?.(newValue ? Number(newValue) : null);
      setIsHoursUpdated(true);
    }
  };

  const handleMinutesChange = (newValue: string) => {
    if (minuteRegx.test(newValue) || isEmpty(newValue)) {
      setDisplayMinutes(newValue);
      onMinutesChange?.(newValue ? Number(newValue) : null);
      setIsMinutesUpdated(true);
    }
  }

  useEffect(() => {
    if (hours == null || isHoursUpdated)
      return;

    setDisplayHours(pad0(hours));
    setIsHoursUpdated(true);
  }, [hours]);

  useEffect(() => {
    if (minutes == null || isMinutesUpdated)
      return;

    setDisplayMinutes(pad0(minutes));
    setIsMinutesUpdated(true);
  }, [minutes]);

  return (
      <Container>
        <LocalTextInput 
          value={displayHours}
          placeholder={placeholderForHours ?? "08"}
          onTextChange={handleHoursChange}
          onBlur={() => {
            setDisplayHours(pad0(hours));
            onHoursBlur?.();
          }}
          error={errorHours}
          {...props}
        />
        :
        <LocalTextInput
          value={displayMinutes} 
          placeholder={placeholderForMinutes ?? "00"}
          onTextChange={handleMinutesChange}
          onBlur={() => {
            setDisplayMinutes(pad0(minutes));
            onMinutesBlur?.();
          }}
          error={errorMinutes}
          {...props}
        />
      </Container>
  )
}

export default TimeField;