import { FunctionComponent, useEffect, useState } from "react";
import {
  CurrencyInput,
  CurrencyInputSmall,
  Column10,
  Column5,
  FieldCell,
  HeadingContainer,
  LabelCell,
  LocalTextInput,
  Note,
  RadioButtonsWrapper,
  Row10,
  Row5,
  SectionHeadingLarge,
  GridRowsContainer,
  GridRow,
  UnitText,
  GridRowBorderless,
  LocalTextInputExtend,
  ContentAndButtonContainer,
  LocalSelectSmall,
  OrgInfoReflectButton,
  LocalSelectExtraSmall,
  CurrencyInputExtraSmall,
  LocalNumberFieldSmall,
} from "../commonStyle";
import Label from "../../../../core/components/Label";
import Radio from "../../../../core/components/Radio";
import styled from "styled-components";
import { JobFormSectionProps } from "../type";
import Text from "../../../../core/components/Text";
import AmountCalcField from "../components/AmountCalcField";
import { JobSalaryType } from "../../../enums/job";
import { canAssignEditableTrans, convertMonthsToOptions, findItemByKind, isEmpty, isFirstEmptySecondNot, prepareNewEditableTranslations } from "../../../../core/utils";
import { useTranslation } from "react-i18next";
import { SelectOption } from "../../../../core/components/Select";
import { DAY_OF_MONTH_ITEMS } from "../../../constants/options";
import { EcPaymentMethod, EcWagePaymentType } from "../../../../core/enums/employmentConditions";
import NumberField from "../../../../core/components/NumberField";
import { getMonths } from "../../../../core/apis/option";
import MultiSelect from "../../../../core/components/MultiSelect";
import { useJobPostCategory } from "../../../../core/hooks";
import { LanguageCode } from "../../../../core/enums/language";
import { JobExtended } from "../../../types/api";
import { PAYROLL_MONTH_OPTIONS } from "../../../../core/options/payment";
import MultilingualInputField from "../components/MultilingualInputField";
import { EditableTranslationKind } from "../../../enums/translation";
import { useReducerContext } from "../../../../core/contexts/ReducerContext";

interface SalarySectionProps extends JobFormSectionProps {}

const ItemContainer = styled(Row10)`
  background: #F7F7F7;
  padding: 10px;
  border-radius: 12px;
  
`;

const LabelContainer = styled.div`
  min-height: 36px;
  width: 70px;
  display: flex;
  align-items: center;
`;

const DeductionLabel = styled(Label)`
  word-break: break-all;
`;

const LocalColumn5 = styled(Column5)`
  flex-grow: 1;
`;

const HourlyDailyAmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border-radius: 12px;
  padding: 10px;
  gap: 10px;
  width: max-content;
  background-color: #F7F7F7;
`;

const SalarySection: FunctionComponent<SalarySectionProps> = ({
  job,
  onChange,
  company,
  onClickLangInputButton
}) => {
  const { state } = useReducerContext();
  const { t: tJob } = useTranslation("translation", { keyPrefix: "job" });
  const { t: tCore } = useTranslation('translation', { keyPrefix: 'core' });
  const [monthOptions, setMonthOptions] = useState<SelectOption[]>([]);
  const [bonusMonths, setBonusMonths] = useState<string[]>([]);
  const jobEditableTrans = job?.editableTranslations;
  const empEditableTrans = state.company?.employer?.editableTranslations;
  const jobPostCategory = useJobPostCategory();
  const industryName = jobPostCategory.getIndustryNameByJobTypeId(job?.jobTypeId, LanguageCode.English);
  const isConstructionIndustry = industryName 
    ? industryName.toLowerCase().includes('construction')
    : false;

  const showReflectBtnForPayrollFields1 = [
    isFirstEmptySecondNot(
      job?.ecCompensationPayrollCutOffDate_1,
      company?.employer?.compensationPayrollCutOffDate_1,
    ),
    isFirstEmptySecondNot(
      job?.ecCompensationPayrollMonth_1,
      company?.employer?.compensationPayrollMonth_1,
    ),
    isFirstEmptySecondNot(
      job?.ecCompensationPayrollDate_1,
      company?.employer?.compensationPayrollDate_1,
    ),
  ].some(Boolean);

  const showReflectBtnForPayrollFields2 = [
    isFirstEmptySecondNot(
      job?.ecCompensationPayrollCutOffDate_2,
      company?.employer?.compensationPayrollCutOffDate_2,
    ),
    isFirstEmptySecondNot(
      job?.ecCompensationPayrollMonth_2,
      company?.employer?.compensationPayrollMonth_2,
    ),
    isFirstEmptySecondNot(
      job?.ecCompensationPayrollDate_2,
      company?.employer?.compensationPayrollDate_2,
    ),
  ].some(Boolean);

  const showReflectBtnForBonus = isFirstEmptySecondNot(
      job?.bonus,
      company?.employer?.bonus,
    );

  const onClickReflectBtnForPayrollFields1 = () => {
    const data: Partial<JobExtended> = {};
    
    if (company?.employer?.compensationPayrollCutOffDate_1) 
      data.ecCompensationPayrollCutOffDate_1 = company?.employer?.compensationPayrollCutOffDate_1;

    if (company?.employer?.compensationPayrollMonth_1)
      data.ecCompensationPayrollMonth_1 = company?.employer?.compensationPayrollMonth_1;
    
    if (company?.employer?.compensationPayrollDate_1)
      data.ecCompensationPayrollDate_1 = company?.employer?.compensationPayrollDate_1;

    onChange?.(data);
  }

  const onClickReflectBtnForPayrollFields2 = () => {
    const data: Partial<JobExtended> = {};
    
    if (company?.employer?.compensationPayrollCutOffDate_2) 
      data.ecCompensationPayrollCutOffDate_2 = company?.employer?.compensationPayrollCutOffDate_2;
    
    if (company?.employer?.compensationPayrollMonth_2)
      data.ecCompensationPayrollMonth_2 = company?.employer?.compensationPayrollMonth_2;

    if (company?.employer?.compensationPayrollDate_2)
      data.ecCompensationPayrollDate_2 = company?.employer?.compensationPayrollDate_2;

    onChange?.(data);
  }

  const onClickReflectBtnForSalaryRaise = () => {
    onChange?.({
      bonus: company?.employer?.bonus!
    });
  }
  
  useEffect(() => {
    (async () => {
      const months = await getMonths();
      setMonthOptions(convertMonthsToOptions(months));
    })();
  }, []);

  useEffect(() => {
    setBonusMonths(job?.bonusMonths?.map(bm => bm.id.toString()) ?? []);
  }, [job?.bonusMonths])

  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>{tJob("wage")}</SectionHeadingLarge>
      </HeadingContainer>
      <GridRowsContainer>

        {/* 支給形態 */}
        <GridRow>
          <LabelCell>
            <Label required>{tJob('salary_type')}</Label>
          </LabelCell>
          <FieldCell>
            <Column5>
              <RadioButtonsWrapper>
                <Radio 
                  checked={job?.salaryType === JobSalaryType.Monthly}
                  onValueChange={() => { onChange?.({ 
                    salaryType: JobSalaryType.Monthly,
                    ecBaseWageType: EcWagePaymentType.Monthly
                  }) }}
                  error={isEmpty(job?.salaryType)}
                >
                  {tJob('salary_monthly')}
                </Radio>
                <Radio 
                  checked={job?.salaryType === JobSalaryType.Daily}
                  onValueChange={() => { onChange?.({ 
                    salaryType: JobSalaryType.Daily,
                    ecBaseWageType: EcWagePaymentType.Daily
                  }) }}
                  error={isEmpty(job?.salaryType)}
                >
                  {tJob('salary_daily')}
                </Radio>
                <Radio 
                  checked={job?.salaryType === JobSalaryType.Hourly}
                  onValueChange={(val) => { onChange?.({ 
                    salaryType: JobSalaryType.Hourly,
                    ecBaseWageType: EcWagePaymentType.Hourly
                  }) }}
                  error={isEmpty(job?.salaryType)}
                >
                  {tJob('salary_hourly')}
                </Radio>
              </RadioButtonsWrapper>
              { (job?.salaryType === JobSalaryType.Daily || 
                 job?.salaryType === JobSalaryType.Hourly) &&
                 <HourlyDailyAmountContainer>
                    <Text>{tJob('hourly_daily_salary')}</Text>
                    <Row10>
                      <CurrencyInputSmall 
                        placeholder="1200"
                        value={job?.hourlyDailySalary ?? ""}
                        onValueChange={(val) => { onChange?.({ hourlyDailySalary: val }) }} 
                        error={
                          (job?.salaryType === JobSalaryType.Daily ||
                           job?.salaryType === JobSalaryType.Hourly) 
                           ? isEmpty(job?.hourlyDailySalary)
                           : false
                        }
                      />
                      ~
                      <CurrencyInputSmall 
                        placeholder="4500" 
                        value={job?.hourlyDailySalaryUpperLimit ?? ""}
                        onValueChange={(val) => { onChange?.({ hourlyDailySalaryUpperLimit: val }) }}
                      />
                      <UnitText>{tCore('units.yen')}</UnitText>
                    </Row10>
                 </HourlyDailyAmountContainer>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 基本給 */}
        <GridRow>
          <LabelCell>
            <Label required>{tJob('gross_salary')}</Label>
          </LabelCell>
          <FieldCell>
            <Column10>
              <Note>
                {tJob('note_on_gross_salary')}
                <br />
                {tJob('gross_salary_upper_limit_placeholder')}
              </Note>
              <Column5>              
                <Row10>
                  <CurrencyInputSmall 
                    placeholder="200000"
                    value={job?.grossSalary ?? ""}
                    onValueChange={(val) => { onChange?.({ 
                      grossSalary: val,
                      ecBaseWageAmount: val
                    }) }} 
                    error={isEmpty(job?.grossSalary)}
                  />
                  ~
                  <CurrencyInputSmall 
                    placeholder="250000" 
                    value={job?.grossSalaryUpperLimit ?? ""}
                    onValueChange={(val) => { onChange?.({ grossSalaryUpperLimit: val }) }}
                  />
                  <UnitText>{tCore('units.yen')}</UnitText>
                </Row10>
              </Column5>
            </Column10>
          </FieldCell>
        </GridRow>

        {/* 1時間あたりの金額 (*月給・日給の場合) */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('amount_per_hour')}</Label>
          </LabelCell>
          <FieldCell>
            <Row5>
              <CurrencyInput 
                disabled={
                  !(job?.salaryType === JobSalaryType.Hourly ||
                    job?.salaryType === JobSalaryType.Daily)} 
                value={job?.ecBaseWageAmountPerHour ?? ""}
                onValueChange={(val) => { onChange?.({ ecBaseWageAmountPerHour: val }) }}
              />
              <UnitText>{tCore('units.yen')}</UnitText>
            </Row5>
          </FieldCell>
        </GridRow>

        {/* 締切日/支払日1 */}
        <GridRow>
          <LabelCell>
            <Label>締切日/支払日1</Label>
          </LabelCell>
          <FieldCell>
            <ContentAndButtonContainer>
              <Row5>
                <Row5>
                  <Text>締切日</Text>
                  <LocalSelectExtraSmall
                    placeholder={tCore('select')}
                    options={DAY_OF_MONTH_ITEMS}
                    value={job?.ecCompensationPayrollCutOffDate_1}
                    onValueChange={(val) => onChange?.({ ecCompensationPayrollCutOffDate_1: val || null })}
                  />
                  <Text>日</Text>
                </Row5>
                <Text>/</Text>
                <Row5>
                  <Text>支払日</Text>
                  <LocalSelectSmall
                    placeholder={tCore('select')}
                    options={PAYROLL_MONTH_OPTIONS}
                    value={job?.ecCompensationPayrollMonth_1}
                    onValueChange={(val) => onChange?.({ ecCompensationPayrollMonth_1: val || null })}
                  />
                  <LocalSelectExtraSmall
                    placeholder={tCore('select')}
                    options={DAY_OF_MONTH_ITEMS}
                    value={job?.ecCompensationPayrollDate_1}
                    onValueChange={(val) => onChange?.({ ecCompensationPayrollDate_1: val || null })}
                  />
                  <Text>日</Text>
                </Row5>
              </Row5>
              { showReflectBtnForPayrollFields1 &&
                <OrgInfoReflectButton
                  onClick={onClickReflectBtnForPayrollFields1}
                >
                  組織情報を反映
                </OrgInfoReflectButton>
              }
            </ContentAndButtonContainer>
          </FieldCell>
        </GridRow>

        {/* 締切日/支払日2 */}
        <GridRow>
          <LabelCell>
            <Label>締切日/支払日2</Label>
          </LabelCell>
          <FieldCell>
            <ContentAndButtonContainer>
              <Row5>
                <Row5>
                  <Text>締切日</Text>
                  <LocalSelectExtraSmall
                    placeholder={tCore('select')}
                    options={DAY_OF_MONTH_ITEMS}
                    value={job?.ecCompensationPayrollCutOffDate_2}
                    onValueChange={(val) => onChange?.({ ecCompensationPayrollCutOffDate_2: val || null })}
                  />
                  <Text>日</Text>
                </Row5>
                <Text>/</Text>
                <Row5>
                  <Text>支払日</Text>
                  <LocalSelectSmall
                    placeholder={tCore('select')}
                    options={PAYROLL_MONTH_OPTIONS}
                    value={job?.ecCompensationPayrollMonth_2}
                    onValueChange={(val) => onChange?.({ ecCompensationPayrollMonth_2: val || null })}
                  />
                  <LocalSelectExtraSmall
                    placeholder={tCore('select')}
                    options={DAY_OF_MONTH_ITEMS}
                    value={job?.ecCompensationPayrollDate_2}
                    onValueChange={(val) => onChange?.({ ecCompensationPayrollDate_2: val || null })}
                  />
                  <Text>日</Text>
                </Row5>
              </Row5>

              { showReflectBtnForPayrollFields2 &&
                <OrgInfoReflectButton
                  onClick={onClickReflectBtnForPayrollFields2} 
                >
                  組織情報を反映
                </OrgInfoReflectButton>
              }
            </ContentAndButtonContainer>
          </FieldCell>
        </GridRow>

        {/* 支払い方法 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('payment_method')}</Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
            <Radio
              checked={job?.ecCompensationPaymentMethod === EcPaymentMethod.BankTransfer}
              value={EcPaymentMethod.BankTransfer}
              onValueChange={val => onChange?.({ ecCompensationPaymentMethod: val })}
            >
              {tJob('bank_transfer')}
            </Radio>
            <Radio
              checked={job?.ecCompensationPaymentMethod === EcPaymentMethod.Cash}
              value={EcPaymentMethod.Cash}
              onValueChange={val => onChange?.({ ecCompensationPaymentMethod: val })}
            >
              {tJob('currency_payment')}
            </Radio>
            </RadioButtonsWrapper>
          </FieldCell>
        </GridRow>

        {/* 昇給 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('salary_raise')}</Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio
                checked={job?.salaryRaise === true}
                onValueChange={() => onChange?.({ 
                  salaryRaise: true,
                  ecCompensationWageRaise: true
                })}
              >
                {tCore('option_available')}              
              </Radio>
              <Radio
                checked={job?.salaryRaise === false}
                onValueChange={val => onChange?.({ 
                  salaryRaise: false,
                  ecCompensationWageRaise: false
                
                })}
              >
                {tCore('option_not_available')}              
              </Radio>
            </RadioButtonsWrapper>
          </FieldCell>
        </GridRow>

        {/* 昇給の時期・金額等 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('salary_raise_timing_amount_etc')}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInputExtend
              placeholder="年２回査定（6月、12月"
              value={job?.ecCompensationWageRaiseTimeAmountEtc ?? ""}
              onTextChange={(val) => onChange?.({ 
                ecCompensationWageRaiseTimeAmountEtc: val
              })}
            />
          </FieldCell>
        </GridRow>

        {/* 賞与 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('bonus')}</Label>
          </LabelCell>
          <FieldCell>
            <ContentAndButtonContainer>
              <RadioButtonsWrapper>
                <Radio
                  checked={job?.bonus === true}
                  onValueChange={() => onChange?.({ 
                    bonus: true,
                    ecCompensationBonus: true
                  })}
                >
                  {tCore('option_available')}              
                </Radio>
                <Radio
                  checked={job?.bonus === false}
                  onValueChange={() => onChange?.({ 
                    bonus: false,
                    ecCompensationBonus: false
                  })}
                >
                  {tCore('option_not_available')}              
                </Radio>
              </RadioButtonsWrapper>

              { showReflectBtnForBonus &&
                  <OrgInfoReflectButton
                    onClick={onClickReflectBtnForSalaryRaise}
                  >
                    組織情報を反映
                  </OrgInfoReflectButton>
              }
            </ContentAndButtonContainer>
          </FieldCell>
        </GridRow>

        {/* 賞与の支給回数（年間） */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('number_of_bonus_payments_per_year')}</Label>
          </LabelCell>
          <FieldCell>
            <NumberField
              value={
                job?.numberOfBonusPaymentsPerYear ?? ""}
              placeholder="2"
              onValueChange={(val) => onChange?.
                ({ 
                  numberOfBonusPaymentsPerYear: val,
                  ecCompensationBonusNumberOfPaymentsPerYear: val 
                })}
            />
          </FieldCell>
        </GridRow>

        {/* 賞与の支給時期 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('bonus_payment_time')}</Label>
          </LabelCell>
          <FieldCell>
            <MultiSelect
              placeholder={tCore('select')}
              options={monthOptions ?? []}
              value={bonusMonths ?? []}
              onValueChange={(val) => {
                setBonusMonths(val);
                onChange?.({ 
                  bonusMonthIds: val,
                  ecBonusMonthIds: val
                });
              }}
            />
          </FieldCell>
        </GridRow>

        {/* 賞与の金額など */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('bonus_amount_etc')}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInputExtend
              placeholder="年2回査定（3〜5万円程度）"
              value={job?.ecCompensationBonusAmountEtc ?? ""}
              onTextChange={(val) => onChange?.({ 
                ecCompensationBonusAmountEtc: val
              })}
            />
          </FieldCell>
        </GridRow>

        {/* 退職金 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('severance_payment')}</Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio
                checked={job?.ecCompensationSeverancePayment === true}
                onValueChange={() => onChange?.({ 
                  ecCompensationSeverancePayment: true,
                })}
              >
                {tCore('option_available')}              </Radio>
              <Radio
                checked={job?.ecCompensationSeverancePayment === false}
                onValueChange={() => onChange?.({ 
                  ecCompensationSeverancePayment: false,
                })}
              >
                {tCore('option_not_available')}              </Radio>
            </RadioButtonsWrapper>
          </FieldCell>
        </GridRow>

        {/* 退職金の条件・金額等 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('severance_payment_condition_amount_etc')}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInputExtend
              placeholder="退職金規程の支給基準による"
              value={job?.ecCompensationSeverancePaymentTimeAmountEtc ?? ""}
              onTextChange={(val) => onChange?.({ 
                ecCompensationSeverancePaymentTimeAmountEtc: val
              })}
            />
          </FieldCell>
        </GridRow>

        {/* 休業手当 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('leave_allowance')}</Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio
                checked={job?.ecCompensationLeaveAllowance === true}
                onValueChange={() => onChange?.({ 
                  ecCompensationLeaveAllowance: true,
                })}
              >
                {tCore('option_available')}             
              </Radio>
              <Radio
                checked={job?.ecCompensationLeaveAllowance === false}
                onValueChange={() => onChange?.({ 
                  ecCompensationLeaveAllowance: false,
                })}
              >
                {tCore('option_not_available')}
              </Radio>
            </RadioButtonsWrapper>
          </FieldCell>
        </GridRow>

        {/* 休業手当の支給額（平均賃金の割合）*/}
        <GridRow>
          <LabelCell>
            <Label>{tJob('leave_allowance_amount')}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInputExtend
              value={job?.ecCompensationLeaveAllowanceRate ?? ""}
              placeholder="60%"
              onTextChange={(val) => onChange?.({ 
                ecCompensationLeaveAllowanceRate: val
              })}
            />
          </FieldCell>
        </GridRow>

        {/* 固定残業代（名称）*/}
        <MultilingualInputField
          label={'固定残業代（名称）'}  
          translation={findItemByKind(
            job?.editableTranslations, EditableTranslationKind.BenefitsFixedOvertimePayName
          )}
          onClickInputButton={() =>
            onClickLangInputButton?.({
              heading: "固定残業代（名称）",
              kind: EditableTranslationKind.BenefitsFixedOvertimePayName
            })
          }
          showOrgInfoReflectButton={
            canAssignEditableTrans(
              jobEditableTrans,
              empEditableTrans,
              EditableTranslationKind.BenefitsFixedOvertimePayName
            )
          }
          onClickOrgInfoReflectButton={
            () => onChange?.({
              editableTranslations: prepareNewEditableTranslations(
                jobEditableTrans,
                empEditableTrans,
                EditableTranslationKind.BenefitsFixedOvertimePayName
              )
            })
          }
        />

        {/* 固定残業代（金額）*/}
        <GridRow>
          <LabelCell>
            <Label>固定残業代（金額）</Label>
          </LabelCell>
          <FieldCell>
            <Row5>
              <CurrencyInputExtraSmall
                placeholder="4000"
                value={job?.ecBenefitsFixedOvertimePayAmount ?? ""}
                onValueChange={(val) => onChange?.({ 
                  ecBenefitsFixedOvertimePayAmount: val
                })}
              />
              <UnitText>{tCore('units.yen')}</UnitText>
            </Row5>
          </FieldCell>
        </GridRow>

        {/* 固定残業代（支給要件）*/}
        <GridRow>
          <LabelCell>
            <Label>固定残業代（支給要件）</Label>
          </LabelCell>
          <FieldCell>
            <Row5>
              <Text>
                時間外労働の有無にかかわらず、
              </Text>
              <LocalNumberFieldSmall
                value={job?.ecBenefitsFixedOvertimePayPaymentRequirement_1 ?? ""}
                placeholder="5"
                onValueChange={(val) => onChange?.({ 
                  ecBenefitsFixedOvertimePayPaymentRequirement_1: val?.toString() ?? ""
                })}
                style={{ textAlign: 'right' }}
              />
              <Text>
                時間分の時間外手当として支給。
              </Text>
            </Row5>

            <Row5>
              <LocalNumberFieldSmall
                value={job?.ecBenefitsFixedOvertimePayPaymentRequirement_2 ?? ""}
                placeholder="5"
                onValueChange={(val) => onChange?.({ 
                  ecBenefitsFixedOvertimePayPaymentRequirement_2: val?.toString() ?? ""
                })}
                style={{ textAlign: 'right' }}
              />
              <Text>
                時間を超える時間外労働分についての割増賃金は追加で支給
              </Text>
            </Row5>
          </FieldCell>
        </GridRow>

        {/* 手当 */}
        <GridRowBorderless>
          <LabelCell>
            <Label>{tJob('allowance')}</Label>
          </LabelCell>
          <FieldCell>
            <Column10>
              <Note>
                {tJob('enter_estimated_amount')}
              </Note>
              <ItemContainer>
                <Column5>
                  <Note>{tJob('name')}</Note>
                  <LocalTextInput 
                    placeholder="皆勤手当"
                    value={job?.ecBenefitsBenefit_1Name ?? ""}
                    onTextChange={(val) => onChange?.({ ecBenefitsBenefit_1Name: val })}
                  />
                </Column5>
                <Column5>
                  <Note>{tJob('amount_of_money')}</Note>
                  <CurrencyInputSmall 
                    placeholder="8000"
                    value={job?.ecBenefitsBenefit_1Amount ?? ""}
                    onValueChange={(val) => onChange?.({ ecBenefitsBenefit_1Amount: val })}
                  />
                </Column5>
                <LocalColumn5>
                  <Note>{tJob('calculation_method')}</Note>
                  <LocalTextInputExtend
                    placeholder="賃金計算期間中に欠勤無しの場合に支給"
                    value={job?.ecBenefitsBenefit_1CalculationMethod ?? ""}
                    onTextChange={(val) => onChange?.({ ecBenefitsBenefit_1CalculationMethod: val })}
                  />
                </LocalColumn5>
              </ItemContainer>  

              <ItemContainer>
                <Column5>
                  <Note>{tJob('name')}</Note>
                  <LocalTextInput 
                    placeholder="皆勤手当"
                    value={job?.ecBenefitsBenefit_2Name ?? ""}
                    onTextChange={(val) => onChange?.({ ecBenefitsBenefit_2Name: val })}
                  />
                </Column5>
                <Column5>
                  <Note>{tJob('amount_of_money')}</Note>
                  <CurrencyInputSmall 
                    placeholder="8000"
                    value={job?.ecBenefitsBenefit_2Amount ?? ""}
                    onValueChange={(val) => onChange?.({ ecBenefitsBenefit_2Amount: val })}
                  />
                </Column5>
                    <LocalColumn5>
                      <Note>{tJob('calculation_method')}</Note>
                      <LocalTextInputExtend
                        placeholder="賃金計算期間中に欠勤無しの場合に支給"
                        value={job?.ecBenefitsBenefit_2CalculationMethod ?? ""}
                        onTextChange={(val) => onChange?.({ ecBenefitsBenefit_2CalculationMethod: val })}
                      />
                    </LocalColumn5>
              </ItemContainer>  

              <ItemContainer>
                <Column5>
                  <Note>{tJob('name')}</Note>
                  <LocalTextInput 
                    placeholder="皆勤手当"
                    value={job?.ecBenefitsBenefit_3Name ?? ""}
                    onTextChange={(val) => onChange?.({ ecBenefitsBenefit_3Name: val })}
                  />
                </Column5>
                <Column5>
                  <Note>{tJob('amount_of_money')}</Note>
                  <CurrencyInputSmall 
                    placeholder="8000"
                    value={job?.ecBenefitsBenefit_3Amount ?? ""}
                    onValueChange={(val) => onChange?.({ ecBenefitsBenefit_3Amount: val })}
                  />
                </Column5>
                    <LocalColumn5>
                      <Note>{tJob('calculation_method')}</Note>
                      <LocalTextInputExtend
                        placeholder="賃金計算期間中に欠勤無しの場合に支給"
                        value={job?.ecBenefitsBenefit_3CalculationMethod ?? ""}
                        onTextChange={(val) => onChange?.({ ecBenefitsBenefit_3CalculationMethod: val })}
                      />
                    </LocalColumn5>
              </ItemContainer>  

              <ItemContainer>
                <Column5>
                  <Note>{tJob('name')}</Note>
                  <LocalTextInput 
                    placeholder="皆勤手当"
                    value={job?.ecBenefitsBenefit_4Name ?? ""}
                    onTextChange={(val) => onChange?.({ ecBenefitsBenefit_4Name: val })}
                  />
                </Column5>
                <Column5>
                  <Note>{tJob('amount_of_money')}</Note>
                  <CurrencyInputSmall 
                    placeholder="8000"
                    value={job?.ecBenefitsBenefit_4Amount ?? ""}
                    onValueChange={(val) => onChange?.({ ecBenefitsBenefit_4Amount: val })}
                  />
                </Column5>
                    <LocalColumn5>
                      <Note>{tJob('calculation_method')}</Note>
                      <LocalTextInputExtend
                        placeholder="賃金計算期間中に欠勤無しの場合に支給"
                        value={job?.ecBenefitsBenefit_4CalculationMethod ?? ""}
                        onTextChange={(val) => onChange?.({ ecBenefitsBenefit_4CalculationMethod: val })}
                      />
                    </LocalColumn5>
              </ItemContainer>  

            </Column10>
          </FieldCell>
        </GridRowBorderless>

        {/* 1ヶ月あたりの支払い概要額（手当） */}
        <GridRow>
          <LabelCell />
          <FieldCell>
            <Column10>
              <Text>{tJob('approx_payment_per_month')}</Text>
              <AmountCalcField 
                sum={job?.ecEstimatedPaymentPerMonth}
                sumInputs={[
                  job?.grossSalary,
                  job?.ecBenefitsFixedOvertimePayAmount, 
                  job?.ecBenefitsBenefit_1Amount,
                  job?.ecBenefitsBenefit_2Amount,
                  job?.ecBenefitsBenefit_3Amount,
                  job?.ecBenefitsBenefit_4Amount,
                ]}
                onValueChange={val => onChange?.({ ecEstimatedPaymentPerMonth: val })}
              />
              <Note>
                {tJob('note_on_payment_amount')}
              </Note>
            </Column10>
          </FieldCell>
        </GridRow>

        {/* 主な控除 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('main_deduction')}</Label>
          </LabelCell>
          <FieldCell>
            <Column10>
              <Note>{tJob('enter_estimated_amount')}</Note>
              <Row10>
                <LabelContainer>
                  <DeductionLabel>{tJob('withholding_tax')}</DeductionLabel>
                </LabelContainer>
                <CurrencyInputSmall 
                  placeholder="20000"
                  value={job?.estimatedIncomeTax}
                  onValueChange={(val) => onChange?.({ 
                    estimatedIncomeTax: val,
                    ecDeductionsTaxes: val 
                  }) }
                />
                <UnitText>{tCore('units.yen')}</UnitText>
              </Row10>
              <Row10>
                <LabelContainer>
                  <DeductionLabel>{tJob('social_insurance_premiums')}</DeductionLabel>
                </LabelContainer>
                <CurrencyInputSmall 
                  placeholder="20000"
                  value={job?.estimatedSocialInsurance}
                  onValueChange={(val) => onChange?.({ 
                    estimatedSocialInsurance: val,
                    ecDeductionsSocialInsurancePremiums: val 
                  }) }
                />
                <UnitText>{tCore('units.yen')}</UnitText>
                {/* TODO: replace the raw text with a translation logic */}
                { isConstructionIndustry &&
                  <Text style={{ color: '#E93232' }}>
                    ※土建国保の場合、注意が必要です。
                  </Text>
                }
              </Row10>
              <Row10>
                <LabelContainer>
                  <DeductionLabel>{tJob('employment_insurance_premium')}</DeductionLabel>
                </LabelContainer>
                <CurrencyInputSmall 
                  placeholder="20000"
                  value={job?.ecDeductionsEmploymentInsurancePremiums}
                  onValueChange={(val) => onChange?.({ ecDeductionsEmploymentInsurancePremiums: val }) }
                />
                <UnitText>{tCore('units.yen')}</UnitText>
              </Row10>
              <Row10>
                <LabelContainer>
                  <DeductionLabel>{tJob('food_expenses')}</DeductionLabel>
                </LabelContainer>
                <CurrencyInputSmall 
                  placeholder="20000"
                  value={job?.ecDeductionsFoodExpenses}
                  onValueChange={(val) => { onChange?.({ ecDeductionsFoodExpenses: val }) }} 
                />
                <UnitText>{tCore('units.yen')}</UnitText>
              </Row10>
              <Row10>
                <LabelContainer>
                  <DeductionLabel>{tJob('residence_fee')}</DeductionLabel>
                </LabelContainer>
                <CurrencyInputSmall 
                  placeholder="20000"
                  value={job?.dormitoryFee}
                  onValueChange={(val) => { onChange?.({ 
                    dormitoryFee: val,
                    ecDeductionsHousingExpenses: val 
                  }) }}
                />
                <UnitText>{tCore('units.yen')}</UnitText>
              </Row10>
            </Column10>
          </FieldCell>
        </GridRow>
        
        {/* その他の控除 */}
        <GridRowBorderless>
          <LabelCell>
            <Label>{tJob('other_deductions')}</Label>
          </LabelCell>
          <FieldCell>
            <Column10>
              <Note>
                {tJob('enter_estimated_amount')}
              </Note>
              <ItemContainer>
                <Column5>
                  <Note>{tJob('name')}</Note>
                  <LocalTextInput 
                    placeholder="水道光熱費"
                    value={job?.ecDeductionsOtherDeductions_1Name ?? ""}
                    onTextChange={(val) => onChange?.({ ecDeductionsOtherDeductions_1Name: val })}
                  />
                </Column5>
                <Column5>
                  <Note>{tJob('amount_of_money')}</Note>
                  <CurrencyInputSmall 
                    placeholder="3000"
                    value={job?.ecDeductionsOtherDeductions_1Amount ?? ""}
                    onValueChange={(val) => onChange?.({ ecDeductionsOtherDeductions_1Amount: val })}
                  />
                </Column5>
              </ItemContainer>

              <ItemContainer>
                <Column5>
                  <Note>{tJob('name')}</Note>
                  <LocalTextInput 
                    placeholder="水道光熱費"
                    value={job?.ecDeductionsOtherDeductions_2Name ?? ""}
                    onTextChange={(val) => onChange?.({ ecDeductionsOtherDeductions_2Name: val })}
                  />
                </Column5>
                <Column5>
                  <Note>{tJob('amount_of_money')}</Note>
                  <CurrencyInputSmall 
                    placeholder="3000"
                    value={job?.ecDeductionsOtherDeductions_2Amount ?? ""}
                    onValueChange={(val) => onChange?.({ ecDeductionsOtherDeductions_2Amount: val })}
                  />
                </Column5>
              </ItemContainer>

              <ItemContainer>
                <Column5>
                  <Note>{tJob('name')}</Note>
                  <LocalTextInput 
                    placeholder="水道光熱費"
                    value={job?.ecDeductionsOtherDeductions_3Name ?? ""}
                    onTextChange={(val) => onChange?.({ ecDeductionsOtherDeductions_3Name: val })}
                  />
                </Column5>
                <Column5>
                  <Note>{tJob('amount_of_money')}</Note>
                  <CurrencyInputSmall 
                    placeholder="3000"
                    value={job?.ecDeductionsOtherDeductions_3Amount ?? ""}
                    onValueChange={(val) => onChange?.({ ecDeductionsOtherDeductions_3Amount: val })}
                  />
                </Column5>
              </ItemContainer>

              <ItemContainer>
                <Column5>
                  <Note>{tJob('name')}</Note>
                  <LocalTextInput 
                    placeholder="水道光熱費"
                    value={job?.ecDeductionsOtherDeductions_4Name ?? ""}
                    onTextChange={(val) => onChange?.({ ecDeductionsOtherDeductions_4Name: val })}
                  />
                </Column5>
                <Column5>
                  <Note>{tJob('amount_of_money')}</Note>
                  <CurrencyInputSmall 
                    placeholder="3000"
                    value={job?.ecDeductionsOtherDeductions_4Amount ?? ""}
                    onValueChange={(val) => onChange?.({ ecDeductionsOtherDeductions_4Amount: val })}
                  />
                </Column5>
              </ItemContainer>
              
            </Column10>
          </FieldCell>
        </GridRowBorderless>

        {/* 控除金額の合計 */}
        <GridRow>
          <LabelCell />
          <FieldCell>
            <Column10>
              <Text>{tJob('total_deduction_amount')}</Text>
              <AmountCalcField 
                sum={job?.estimatedTotalDeductions}
                sumInputs={[
                  job?.estimatedIncomeTax,
                  job?.estimatedSocialInsurance,
                  job?.ecDeductionsEmploymentInsurancePremiums,
                  job?.ecDeductionsFoodExpenses,
                  job?.dormitoryFee,
                  job?.ecDeductionsOtherDeductions_1Amount,
                  job?.ecDeductionsOtherDeductions_2Amount,
                  job?.ecDeductionsOtherDeductions_3Amount,
                  job?.ecDeductionsOtherDeductions_4Amount
                ]}
                onValueChange={val => onChange?.({ 
                  estimatedTotalDeductions: val,
                  ecDeductionsTotalDeductions: val 
                })}
              />
            </Column10>
          </FieldCell>
        </GridRow>

        {/* 手取り支給額 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('take_home_pay_amount')}</Label>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Note>{tJob('note_on_take_home_pay')}</Note>
              <AmountCalcField 
                allowNegative
                sum={job?.takeHomeSalary}
                sumInputs={[
                  job?.ecEstimatedPaymentPerMonth,
                  (job?.estimatedTotalDeductions
                    ? -Math.abs(job.estimatedTotalDeductions)
                    : 0
                  )
                ]}
                onValueChange={val => onChange?.({ 
                  takeHomeSalary: val,
                  ecTakeHomeWage: val 
                })}
              />
            </Column5>
          </FieldCell>
        </GridRow>

      </GridRowsContainer>
    </>
  );
};

export default SalarySection;