import { FunctionComponent, useState } from "react";
import styled from "styled-components";

import JobCard from "../../components/JobCard";
import ProposeCandidateCard from "../../components/ProposeCandidateCard";
import { Job } from "../../types/api";
import { useReducerContext } from "../../../core/contexts/ReducerContext";
import { MOBILE_DEVICE } from "../../../core/constants/styles";
import { useMediaQuery } from "react-responsive";
import { getDefaultThumbnailURL } from "../../../core/utils";
import BasicInformationSection from "../../sections/BasicInformationSection";
import SalarySection from "../../sections/SalarySection";
import ExtraWagesSection from "../../sections/ExtraWagesSection";
import CandidateConditionsSection from "../../sections/CandidateConditionsSection";
import WorkHoursSection from "../../sections/WorkHoursSection";
import HolidaysVacationSection from "../../sections/HolidaysVacationSection";
import LivingEnvironmentSection from "../../sections/LivingEnvironmentSection";
import ResignationOtherSection from "../../sections/ResignationOtherSection";
import QuestionAndAnswerSection from "../../sections/QuestionAndAnswerSection";
import Gallery from "../../../core/components/Gallery";
import Button from "../../../core/components/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as OpenIcon } from "../../../assets/icon-open-in-new.svg";

type DetailedViewProps = {
  top: number;
  jobs: Job[];
  questions: { [key: number]: any[] };
  onPropose?: (jobId: number) => void;
  onAsk?: (jobId: number) => void;
  onQuestionsLoad?: (jobId: number) => void;
};

type RightContainerProps = {
  top: number;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 1130px;

  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`;

const LeftContainer = styled.div`
  width: 560px;

  > *:not(:first-child) {
    margin-top: 20px;
  }

  @media ${MOBILE_DEVICE} {
    flex: 1;
    width: unset;

    > *:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

const RightContainer = styled.div<RightContainerProps>`
  position: fixed;
  top: ${(props: RightContainerProps) => `${props.top}px`};
  bottom: 10px;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 560px;
  margin-left: 10px;

  @media ${MOBILE_DEVICE} {
    display: none;
  }
`;

const JobDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 560px;
  padding: 10px 27px 20px 20px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  overflow-y: auto;
  gap: 20px;
`;

const Stack = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const SampleLinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const SampleLinkAnchor = styled.a`
  color:#017698;
  font-size: 14px;
  font-style: normal;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const DetailedView: FunctionComponent<DetailedViewProps> = ({
  top,
  jobs,
  questions,
  onPropose,
  onAsk,
  onQuestionsLoad,
}) => {
  const { state } = useReducerContext();
  const { t } = useTranslation('translation', { keyPrefix: 'job'});
  const [activeIndex, setActiveIndex] = useState(0);
  const activeJob = jobs[activeIndex];
  const isMobileDevice = useMediaQuery({
    query: MOBILE_DEVICE,
  });

  const commonProps = { job: activeJob };

  const handleJobClick = (index: number) => {
    if (isMobileDevice) {
      window.open(`/jobs/${jobs[index].id}`, "_blank");
    } else {
      setActiveIndex(index);
      onQuestionsLoad && onQuestionsLoad(jobs[index].id);
    }
  };

  const handleEmploymentCondtionClick = () => {
    window.open(`/jobs/${activeJob.id}/employment_conditions`, "_blank");
  }

  return (
    <Container>
      <LeftContainer>
        {jobs.map((job, index) => (
          <JobCard
            key={job.id}
            job={job}
            active={index === activeIndex}
            onClick={() => handleJobClick(index)}
          />
        ))}
      </LeftContainer>
      <RightContainer top={top}>
        {activeJob && (
          <>
            <ProposeCandidateCard
              job={activeJob}
              readonly={!state.isPartner && !state.isCandidate}
              onPropose={() => onPropose && onPropose(activeJob.id)}
            />
            <JobDetailContainer data-is-displayed-mobile>
              <Stack>
                <Gallery
                  small
                  style={{ width: "100%", minHeight: 130 }}
                  images={
                    activeJob.images.length > 0
                      ? activeJob.images.map((image) => image.url)
                      : [getDefaultThumbnailURL(activeJob.jobTypeId)]
                  }
                />
              </Stack>
                <SampleLinkContainer>
                  <SampleLinkAnchor onClick={handleEmploymentCondtionClick}>
                    <OpenIcon />
                    { t("check_sample_employment_conditions_doc") }
                  </SampleLinkAnchor>
                </SampleLinkContainer>
              <BasicInformationSection {...commonProps} />
              <SalarySection {...commonProps} />
              <ExtraWagesSection {...commonProps} />
              <CandidateConditionsSection {...commonProps} />
              <WorkHoursSection {...commonProps} />
              <HolidaysVacationSection {...commonProps} />
              <LivingEnvironmentSection {...commonProps} />
              <ResignationOtherSection {...commonProps} />
              <QuestionAndAnswerSection {...commonProps} />
            </JobDetailContainer>
          </>
        )}
      </RightContainer>
    </Container>
  );
};

export default DetailedView;
