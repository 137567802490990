import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import Row from "../../../core/components/Row";
import { ReactComponent as PrintIcon } from "../../../assets/icon-print.svg";
import Column from "../../../core/components/Column";
import Button from "../../../core/components/Button";
import { MOBILE_DEVICE } from "../../../core/constants/styles";
import { useNavigate } from "react-router-dom";
import { EmploymentCondition } from "../../types";
import { useReducerContext } from "../../../core/contexts/ReducerContext";
import EmploymentConditionsTemplate from "../../components/EmploymentConditionsTemplate/EmploymentConditionsTemplate";
import { getEmploymentCondition } from "../../apis/public/documents";
import PageWithoutNavigator from "../../../core/components/PageWithoutNavigator";
import { LanguageCode } from "../../../core/enums/language";
import { getCandidateNationality } from "../../apis/public/nationalities";
import { useNationalities } from "../../../core/hooks";
import { getProposal } from "../../apis/proposals";
import { useParams } from "react-router-dom";
import ACTIONS from "../../../core/constants/actions";
import { getCandidate } from "../../../candidate/apis/candidate";

interface EmploymentConditionsViewPageProps {}

const LocalPage = styled(PageWithoutNavigator)`
  padding: 40px 0 0 0;

  @media ${MOBILE_DEVICE} {
    padding: 20px 0 0 0;
    padding: 40px 20px 0 20px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const PageHeaderContainer = styled(Column)`
  width: 100%;
  margin-bottom: 40px;
`;

const PageHeading = styled.h1`
  margin-top: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #444444;
  margin-bottom: 0;
`;

const ActionButtonsContainer = styled(Row)`
  margin-top: 20px;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
`;

const PrintButton = styled(Button).attrs({
  variant: "secondary",
})`
  width: 120px;
  font-weight: bold;
  gap: 10px;

  &:hover {
    path {
      fill: #444444;
    }
  }
`;

const EmploymentConditionsViewPage: FunctionComponent<
  EmploymentConditionsViewPageProps
> = () => {
  const { state, dispatch } = useReducerContext();
  const {
    getSupportedLangCodeByNationality,
    getCandidateNationality: getNationalityFromCandidate,
  } = useNationalities();
  const { isLoggedIn, isEmployer } = state;
  const defaultLangCode = LanguageCode.English;

  //For public access (the token and ecId are passed as query parameters)
  //(The access is through a link in an email sent to the candidate's email)
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const ecId = params.get("id");
  const isPublicAccess = !isLoggedIn;
  const isInvalidPublicAccess = !isLoggedIn && (!token || !ecId);

  //For employer access (only the proposalId is available)
  const { proposalId } = useParams<{ proposalId: string }>();
  const isAccessByEmployer = isEmployer && isLoggedIn;

  const [candidateName, setCandidateName] = useState("");
  const [candidateLangCode, setCandidateLangCode] =
    useState<LanguageCode>(defaultLangCode);
  const [employmentCondition, setEmploymentCondition] =
    useState<EmploymentCondition>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const navigate = useNavigate();

  const initializeForPublicAccess = async () => {
    const ec = await getEmploymentCondition(ecId!, token!);
    const nationality = await getCandidateNationality(ecId!, token!);
    const langCode = getSupportedLangCodeByNationality(nationality);
    const fullName = ec.employeeEnFullName?.trim() ?? "";

    setCandidateName(fullName);
    setEmploymentCondition(ec);
    setCandidateLangCode((langCode as LanguageCode) || defaultLangCode);
  };

  const initializeForEmployerAccess = async () => {
    const proposal = await getProposal(proposalId!);
    const candidate = await getCandidate(proposal.candidateId!);
    const nationality = getNationalityFromCandidate(candidate);
    const ec = proposal.employmentCondition!;
    const fullName = ec.employeeEnFullName?.trim() ?? "";
    const langCode = getSupportedLangCodeByNationality(nationality);

    setCandidateName(fullName);
    setEmploymentCondition(ec);
    setCandidateLangCode((langCode as LanguageCode) || defaultLangCode);
  };

  useEffect(() => {
    if (isLoaded) 
      return;

    if (isPublicAccess && isInvalidPublicAccess) {
      navigate("/login");
      return;
    }

    (async () => {
      try {
        dispatch({
          type: ACTIONS.START_LOADING,
        });

        if (isPublicAccess) 
          await initializeForPublicAccess();

        if (isAccessByEmployer) 
          await initializeForEmployerAccess();

        setIsLoaded(true);
      } catch (e) {
        navigate("/");
      } finally {
        dispatch({
          type: ACTIONS.STOP_LOADING,
        });
      }
    })();
  }, []);

  return (
    <LocalPage>
      <ContentWrapper>
        {isLoaded && (
          <>
            <PageHeaderContainer>
              <PageHeading>{candidateName}さんの雇用条件書</PageHeading>
              <ActionButtonsContainer>
                <PrintButton onClick={() => window.print()}>
                  <PrintIcon />
                  <span>印刷</span>
                </PrintButton>
              </ActionButtonsContainer>
            </PageHeaderContainer>

            <EmploymentConditionsTemplate
              employmentConditions={employmentCondition}
              transLanguage={candidateLangCode}
            />
          </>
        )}
      </ContentWrapper>
    </LocalPage>
  );
};

export default EmploymentConditionsViewPage;
